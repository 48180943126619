
import { ActionTypes, useStore } from '@/store';
import { IonLabel, IonButton, IonText, IonIcon } from '@ionic/vue';
import { computed, defineComponent, onMounted, ref, Ref } from 'vue';
import { EventInstanceViewModel } from '@/models/EventInstanceViewModel';
import * as moment from 'moment-timezone';
import { ReservationViewModel } from '@/models/ReservationViewModel';
import { useErrorBox } from '@/components/errorBox';
import { NewVisitViewModel } from '@/models/NewVisitModel';
import { createGuid } from '@/common/utils';
import { FamilyMemberDetailedViewModel } from '@/models/UserDetailedViewModel';
import { personOutline, calendarOutline, locationOutline, trashOutline,logInOutline } from 'ionicons/icons';
import { useCalendarStore } from '@/store/calendarStore';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'EventInstance',
  props: {
    id: { type: String },
    visitId: { type: String },
    reservationId: {type: String }
  },
  components:{
    IonLabel,
    IonButton,
    IonText,
    IonIcon,
    },
  setup(props) {

  const momentjs: any = moment;
  moment.locale("fi");
  const store = useStore();
  const calendarStore = useCalendarStore();
  const {showError} = useErrorBox();

  const fullDetails: Ref<EventInstanceViewModel|undefined> = ref(undefined);

  const fullProfile = computed(() => store.getters.fullProfile);
  const familyMember: Ref<FamilyMemberDetailedViewModel|undefined> = ref(undefined);
  const {visits} = storeToRefs(calendarStore);

  const visit: Ref<NewVisitViewModel|undefined> = ref(undefined);
  const reservation: Ref<ReservationViewModel|undefined> = ref(undefined);
  
  const canSignIn: Ref<string|undefined> = ref(undefined);

  onMounted( async() => {
    try {
      if(fullDetails.value === undefined) {      
        fullDetails.value = await calendarStore.getEventInstance(props.id as string);
      }
        
      if(props.reservationId){
        reservation.value = calendarStore.reservations.find(i=>i.id===props.reservationId);
        if(reservation.value){
          familyMember.value = fullProfile.value?.familyMembers.find(i=>i.id === (reservation.value?.familyMemberId ?? "-") )
        }
      }

      await refreshVisits();
      await refreshCanSignIn();

    } catch (err) {   
      showError(err as string, "virhe");
    }
  });
    
  const refreshCanSignIn = async () => {
      if(fullProfile.value && familyMember.value  &&  (reservation.value?.quantity ?? 0)>(visit.value?.quantity ?? 0) ){
        store.dispatch(ActionTypes.IS_VISIT_ALLOWED,{userId: fullProfile.value.userId ?? 0, memberId: familyMember.value.id, eventInstanceId: props.id as string, reservationId: reservation.value?.id, later: false})
          .then(result => { canSignIn.value = result; })
          .catch(_err => { canSignIn.value = undefined; });
      } else {
        canSignIn.value = undefined;
      }
    };

   const refreshVisits = async () => {
    visit.value = undefined;

      if(props.visitId){
        console.log("refreshVisits 1", props.visitId);
        visit.value = visits.value?.find(i=>i.id===props.visitId);
        if(visit.value?.memberId){
          familyMember.value = fullProfile.value?.familyMembers.find(i=>i.id === (visit.value?.memberId ?? "-") )
        }
      }
      
      if(!visit.value && reservation.value){
        console.log("refreshVisits 2", reservation.value);
        const vall = visits.value?.filter(i=>i.memberId===reservation.value?.familyMemberId && i.eventId==props.id);
        if(vall && vall.length>0){
          console.log("refreshVisits 2.1", vall);
          const vm = new NewVisitViewModel();
          vm.eventId = vall[0].eventId;
          vm.memberId = vall[0].memberId;
          vm.visitTime = vall[0].visitTime;
          vm.reservation = vall[0].reservation;
          //sum of all visits
          vm.quantity = 0;
          vall.forEach(i=>vm.quantity += i.quantity);
          visit.value = vm;
        }
      }
  };

  const canCancelReservation = computed(() => {
    if(!fullDetails.value)
      return false;
    const eventInstance: EventInstanceViewModel = fullDetails.value;

    const cancelEndTime = momentjs(eventInstance.beginTime).add(-15, 'm');
    const now = new Date();

    return cancelEndTime>now;
  });


  const signInNow = async () => {
    if(!fullDetails.value || !familyMember.value || !canSignIn.value || !fullProfile.value){
      showError("kirjautuminen ei onnistunut", "virhe");
      return;
    }

    const vm = new NewVisitViewModel();
    vm.eventId= fullDetails.value.id;
    vm.id= createGuid();
    vm.memberId= familyMember.value.id;
    vm.quantity= 1;
    vm.reservation= reservation.value?.id;
    vm.ticketId= canSignIn.value;

    try {
      await calendarStore.addVisit(vm);
      showError("lisättiin tunnille","info");

      await refreshVisits();
      await refreshCanSignIn();

      console.log("signInNow4");
    } catch (err) {
      showError(err as string, "virhe");
    }
  };

  const unRegisterEvent = () => {
    if(!reservation.value)
      return;

      calendarStore.removeReservation(reservation.value?.id)
        .catch(r=>{ showError(r, "virhe"); });   
  };

  return {
    momentjs,
    fullDetails,
    canSignIn,
    familyMember,
    signInNow,
    visit,
    unRegisterEvent,canCancelReservation,
    reservation,
    personOutline,calendarOutline,locationOutline,trashOutline,logInOutline,
  };
  }
});
