
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  loadingController,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonAlert,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { checkmarkOutline, alertOutline, logInOutline, saveOutline, trashOutline, buildOutline } from "ionicons/icons";
import { useStore, ActionTypes } from "../store";
import { useCalendarStore } from '@/store/calendarStore';
import { useErrorBox } from "@/components/errorBox";
import { EventVisitDetailedInfo, EventVisitsDetailedInfo, NewVisitViewModel, SeasonTicketDetailedInfo } from "@/models/NewVisitModel";
import * as moment from 'moment-timezone';
import { ReservationViewModel } from "@/models/ReservationViewModel";
import { createGuid } from "@/common/utils";
import { EventInstanceUpdated, EventInstanceViewModel, PublicProfileViewModel } from "@/models/EventInstanceViewModel";
import { emitter } from "@/components/useEvents";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UnsubscribeFunction } from "emittery";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "PartisipantsView",
  data() {
    return {
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonText,
    IonButton,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonAlert,
},
  setup() {
    const router = useRouter();
    const store = useStore();
    const auth = useAuthStore();
    const calendarStore = useCalendarStore();
    const {showError} = useErrorBox();
    const momentjs: any = moment;
    moment.locale("fi");

    const editor = ClassicEditor;
    const editorConfig = {};

    const visitsInfo: Ref<EventVisitsDetailedInfo|undefined> = ref(undefined);
    const eventInfo: Ref<EventInstanceViewModel|undefined> = ref(undefined);
    const publicProfiles: Ref<Array<PublicProfileViewModel>|undefined> = ref(undefined);

    const editorVisible: Ref<boolean> = ref(false);

    const hasRoleInstructor = computed(() => { 
      return auth.roles?.indexOf("Instructor") >= 0 || auth.roles?.indexOf("Admin") >= 0;});
    
    const hasRoleMerchant = computed(() => { 
      return auth.roles?.indexOf("Merchant") >= 0 || auth.roles?.indexOf("Admin") >= 0;});

    const loading = async () => {
      const loading = await loadingController.create({ message: 'Hetki...' });
      await loading.present();
      return loading;
    }    

    const loadPartisipants = async () => {
      visitsInfo.value = undefined;
      const l = await loading();
      try
      {
        const id = router.currentRoute.value.params["id"].toString();

        visitsInfo.value = await calendarStore.getEventVisits(id);
        publicProfiles.value = await store.dispatch(ActionTypes.GET_PUBLIC_PROFILES, undefined);
        eventInfo.value = await calendarStore.getEventInstance(id);
      }
      catch(err){
        showError(err as string, "virhe");
      }
      finally{
        l.dismiss();
      }

    }

    const visibilityChanged = async () => {
      if (document.visibilityState === 'hidden') {
        console.log("hidden");
      } else {
        await loadPartisipants();
      }
    };

    let emitterDismiss: UnsubscribeFunction|undefined = undefined;

    onMounted(async () => {
      await loadPartisipants();
      document.addEventListener("visibilitychange",visibilityChanged);
      emitterDismiss = emitter.on('eventInstanceUpdated',async (data: EventInstanceUpdated) => {
        if(!data || !visitsInfo.value || !data.id || data.id != visitsInfo.value.id)
          return;        
        await loadPartisipants();
      });
    });

    onUnmounted(async ()=>{
      document.removeEventListener("visibilitychange",visibilityChanged);
      if(emitterDismiss)
        emitterDismiss();
    });

    const unRegisterEvent = async (reservation: ReservationViewModel) => {
      if (!reservation.id) return;

      const l = await loading();
      try {
        const success = await calendarStore.removeReservation(reservation.id);
        if (!success || !visitsInfo.value) return;
        visitsInfo.value.reservations = visitsInfo.value.reservations.filter(i => i.id != reservation.id);
      } catch (r) {
        showError(r, "virhe");
      } finally {
        l.dismiss();
      }
    };

    const removeSigninFromEvent = async (visit: EventVisitDetailedInfo) => {
      if(!visit.id || !visitsInfo.value) return;

      const l = await loading();
      try {
        await calendarStore.removeVisit(visit.id);
        visitsInfo.value.visits = visitsInfo.value.visits.filter(i => i.id != visit.id);
      } catch (r) {
        showError(r, "virhe");
      } finally {
        l.dismiss();
      }
    };
    
    const signInToEvent = async (sticket: SeasonTicketDetailedInfo) => {
      if (!sticket.ticketId) {
        showError("asiakkaan korttia ei löytynyt", "info");
        return;
      }

      if (!visitsInfo.value) {
        showError("osallistujatietoja ei löytynyt", "info");
        return;
      }

      const vm = new NewVisitViewModel();
      vm.eventId = visitsInfo.value.id;
      vm.id = createGuid();
      vm.memberId = sticket.familyMemberId;
      vm.quantity = 1;
      vm.ticketId = sticket.ticketId;

      const l = await loading();
      try {
        await calendarStore.addVisit(vm);
        showError("lisättiin tunnille", "info");
      } catch (err) {
        showError(err, "virhe");
      } finally {
        l.dismiss();
      }
    };

     const isSigned = (sticket: SeasonTicketDetailedInfo) => {

      if( !sticket.ticketId || !visitsInfo.value)
        return false;
      const v = visitsInfo.value.visits.find(i=>i.ticketId == sticket.ticketId);
      return v !== undefined;
     }

      const ticketColor = (sticket: SeasonTicketDetailedInfo) => {
        if(!sticket)
          return "error";

        if(!sticket.ticketActive)
          return "warning";

        return "primary";
     }

    const refreshNow = async (item: any) => {
      await loadPartisipants();
      item.target.complete();
    };

    const save = async () => {
      if(!eventInfo.value)
        return false;

      const l = await loading();

      try {
        const r = await calendarStore.updateEventInstance(eventInfo.value);
        if(r?.id) { eventInfo.value = r; }
         l.dismiss();
        return true;
      } catch(err) {
        const a = await showError(err as string, "virhe");
        l.dismiss();
        await a.onDidDismiss();        
        return false;
      }
    };

    const checkSelectedPublicProfile = (tag1: PublicProfileViewModel, tag2: PublicProfileViewModel) => {	
      // check if the secondary tag is an array of multiple
			if(Array.isArray(tag2))
				return (tag2 as Array<PublicProfileViewModel>).findIndex(i=>tag1.publicProfileId === i.publicProfileId)>-1;

      // compare individual objects
			return tag1.publicProfileId===tag2.publicProfileId;
		};

    const alertResult = async (ev: CustomEvent) => {
      console.log(`Dismissed with role: ${ev.detail.role}`);

      if(ev.detail.role === 'remove'){
        if((visitsInfo.value?.reservations.length ?? 0) > 0 || (visitsInfo.value?.visits.length ?? 0) > 0){
          await showError("Tunnin varaukset ja osallistujat pitää poistaa ennen tunnin poistamiseta.", "virhe");
          return;
        }

        if(!eventInfo.value?.id)
          return;

        calendarStore.removeEventInstance(eventInfo.value.id)
          .then(() => { router.back(); })
          .catch(r=>{ showError(r, "virhe");});
      }

    }

    return {
      visitsInfo,eventInfo,
      momentjs,
      hasRoleInstructor,
      hasRoleMerchant,
      unRegisterEvent,
      signInToEvent,
      isSigned,
      checkmarkOutline,saveOutline,trashOutline,buildOutline,
      alertOutline,
      logInOutline,
      ticketColor,
      refreshNow,save,
      checkSelectedPublicProfile,
      publicProfiles,editor,editorConfig,
      editorVisible,
      removeSigninFromEvent,
      alertResult,
    };
  }
});
