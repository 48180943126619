

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonButton,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonIcon,
  // IonBackButton,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref, watch } from "vue";
import { calendarOutline, peopleOutline,repeatOutline, } from 'ionicons/icons';
import { useRouter } from "vue-router";
import { useStore, ActionTypes } from "../store";
import { useErrorBox } from '@/components/errorBox';
import { NewReservationRequest, ReservationViewModel } from '@/models/ReservationViewModel';
import { FamilyMemberDetailedViewModel, UserDetailedViewModel } from '@/models/UserDetailedViewModel';
import { EventInstanceViewModel } from '@/models/EventInstanceViewModel';
import * as moment from 'moment-timezone';
import { useAuthStore } from "@/store/authStore";
import { useCalendarStore } from "@/store/calendarStore";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "ReserveView",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonButton,
    IonMenuButton,
    IonRefresher,
    IonRefresherContent,
    IonText,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const calendarStore = useCalendarStore();
    const auth = useAuthStore();
    const {showError} = useErrorBox();
    const id = router.currentRoute.value.params["id"].toString();
    
    const momentjs: any = moment;

    const processing: Ref<boolean> = ref(true);
    const processing2: Ref<boolean> = ref(false);

    const customer: Ref<UserDetailedViewModel|undefined> = ref(undefined);
    const familyMember: Ref<FamilyMemberDetailedViewModel|undefined> = ref(undefined);
    const eventInstance: Ref<EventInstanceViewModel|undefined> = ref(undefined);

    const hasRoleAdmins = computed(() => { return auth.roles?.indexOf("Admin") >= 0;});

    const hasRequiredCard: Ref<boolean> = ref(false);

    const { reservations } = storeToRefs(calendarStore);

    const loadCalendar = async () => {
      try {
        if (id == "") {
          showError("Tuntia ei löytynyt", "virhe");
          return;
        }

        processing.value = true;
        
        eventInstance.value = await calendarStore.getEventInstance(id);

        // Select first family member by default
        if (customer.value && customer.value.familyMembers.length > 0 && !familyMember.value) {
          familyMember.value = customer.value.familyMembers[0];
        }

        if (customer.value && familyMember.value) {
          hasRequiredCard.value = await store.dispatch(ActionTypes.IS_RESERVE_ALLOWED, {
            userId: customer.value.userId ?? -1,
            memberId: familyMember.value.id,
            eventInstanceId: eventInstance.value.id
          });
        }

      } catch (err) {
        showError(err as string, "virhe");
      } finally {
        processing.value = false;
      }
    };

    watch(() => familyMember.value, (fm) => {
      if (fm?.id) loadCalendar();
    });

    onMounted(async () => {
      customer.value = store.getters.fullProfile;
      familyMember.value = store.getters.familyMember;
      await loadCalendar();
    });

    const registerToEvent = async () => {
      if(!eventInstance.value?.id){
        showError("Tuntia ei löydy.", "virhe");
        return;
      }

      if(processing2.value)
        return;

      const data = new NewReservationRequest();
      data.eventInstanceId = eventInstance.value.id;
      data.quantity = 1;
      data.userId = customer.value?.userId ?? 0;
      data.familyMemberId = familyMember.value?.id ?? "";

      processing2.value = true;

      try {
        await calendarStore.addReservation(data);
        eventInstance.value.reservations++;
      } catch (r) {
        showError(r, "virhe");
      } finally {
        processing2.value = false;
      }
    };


    const unRegisterEvent2 = async (reservation: ReservationViewModel) => {
      if (processing2.value) return;

      processing2.value = true;
      try {
        await calendarStore.removeReservation(reservation.id);
        if (eventInstance.value) eventInstance.value.reservations--;
      } catch (r) {
        showError(r, "virhe");
      } finally {
        processing2.value = false;
      }
    };

    const isRegistered = computed(() => {
      if(!eventInstance.value?.id)
        return false;

      return !!reservations.value
        .find(i=>i.eventInstanceId === eventInstance.value?.id && i.familyMemberId === familyMember.value?.id )
    });

    const isOver = computed(() => {
      const cancelEndTime = momentjs(eventInstance.value?.endTime ?? eventInstance.value?.beginTime).add(-15, 'm');
      return cancelEndTime < new Date();
    });

    const canReserve = computed(() => {
      if(!eventInstance.value?.eventTemplate?.requireReservation)
         return false;
      
     return isOver;
    });


    const nextFamilyMember = () => {
      if(!familyMember.value || !customer.value)
      return;

      let index = customer.value.familyMembers.findIndex(i=>i.id == familyMember.value?.id);
      index++;
      if(index == customer.value.familyMembers.length || index < 0)
        index = 0;

      familyMember.value = customer.value.familyMembers[index];      
    };

    const refreshNow = async (item: any) => {
      await store.dispatch(ActionTypes.REFRESH_FULL_PROFILE,true);
      await calendarStore.refreshReservations();
      await loadCalendar();
      item.target.complete();
    };

    const openParticipantList = () => {
      router.replace('/partisipants/'+eventInstance.value?.id);
    };

    return {
      router, reservations,
      calendarOutline,repeatOutline,
      isRegistered,
      canReserve,isOver,
      processing,processing2,
      peopleOutline,
      familyMember,
      nextFamilyMember,
      eventInstance,
      momentjs,
      registerToEvent,
      refreshNow,
      customer,
      unRegisterEvent2,
      hasRequiredCard,
      hasRoleAdmins, openParticipantList,
    };
  },
});
