
import { IonIcon, IonText } from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref } from 'vue';
import { LocationViewModel } from '@/models/LocationViewModel';
import { useCalendarStore } from '@/store/calendarStore';
import { locationOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'LocationItem',
  props: {
    id: { type: String },
    style: { type: Object, default() { return {} } },
    color: { type: String as PropType<PredefinedColors>, default: "primary" },
  },
  components: { IonText, IonIcon },
  setup(props) {
    const calendarStore = useCalendarStore();
    const fullDetails: Ref<LocationViewModel|undefined> = ref(undefined);

    onMounted( async () => {      
      fullDetails.value = await calendarStore.getLocation(props.id as string);
    });

    return { fullDetails, icons: {
      locationOutline
      } }
  }
});
