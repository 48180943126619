
import { IonButton, IonText, IonIcon, IonItem, IonContent, IonFab, IonFabButton, 
IonHeader, IonPage, IonToolbar,IonMenuButton,
IonRefresher, IonRefresherContent,
IonButtons, IonList, modalController } from '@ionic/vue';
import { defineComponent, computed, onMounted, Ref, ref, onUnmounted, } from "vue";
import { logInOutline, happyOutline, hammerOutline, add,calendar,peopleOutline, cartOutline,personCircleOutline,receiptOutline } from 'ionicons/icons';
import { useRouter } from 'vue-router';
import { useStore, ActionTypes} from "../store";
import { OrganizationInfo } from "../models/Organization";
import SelectFamilyMemberModal from './SelectFamilyMember.vue'
import EventInstance from '../components/EventInstance.vue'
import * as moment from 'moment-timezone';
import { ReservationViewModel } from '@/models/ReservationViewModel';
import { useErrorBox } from '@/components/errorBox';
import TicketButton from "@/components/TicketButton.vue";
import { InfoPageModel } from '@/models/InfoPageModel';
import { useAuthStore } from '@/store/authStore';
import { storeToRefs } from 'pinia';
import { useCalendarStore } from '@/store/calendarStore';
import { useFinancialStore } from '@/store/financialStore';
import { ShoppingCartSimpleViewModel } from '@/models/ShoppingCart';
export default defineComponent({
  name: 'HomeView',
  components: {
    IonText,
    IonIcon,
    IonItem,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonList,
    EventInstance,
    IonButton,
    IonRefresher, IonRefresherContent,
    TicketButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authStore = useAuthStore();
    const calendarStore = useCalendarStore();
    const financialStore = useFinancialStore();
    const momentjs: any = moment;
    const {showError} = useErrorBox();
          
    const organisation = computed(() => store.getters.organisation);
    // const userInfo = computed(() => store.getters.userInfo);
    const cart = computed(() => store.getters.cart);
    const familyMember = computed(() => store.getters.familyMember);

    const openShoppingCarts: Ref<Array<ShoppingCartSimpleViewModel>> = ref([]);
    const {reservations, visits} = storeToRefs(calendarStore);

    const fullProfile = computed(() => store.getters.fullProfile);

    const { id: userId } = storeToRefs(authStore)

    const newsPage: Ref<InfoPageModel|undefined> = ref(undefined);

    const visibilityChanged = async (_e: Event) => {
      if (document.visibilityState === 'hidden')
        return;

      if(store.getters.fullProfile === undefined)
        return;
      
      await calendarStore.refreshReservations();
      const newsPageResult = await store.dispatch(ActionTypes.GET_INFO_PAGE_BY_CODE, "etusivu");
      newsPage.value = newsPageResult;      
    };

    const refresh = (async (force: boolean) => {
      try
      {
        const logged = authStore.checkAuth();
        if(!logged) {
          router.replace("/login"); 
          return; 
          }

        if(force || store.getters.fullProfile === undefined || familyMember.value === undefined){      
          await store.dispatch(ActionTypes.REFRESH_FULL_PROFILE,true);
        }

        if(force || visits.value === undefined || visits.value.length === 0){     
          await calendarStore.refreshVisits();
        }

        if(force || reservations.value === undefined || reservations.value.length === 0){     
          await calendarStore.refreshReservations();
        }

        const newsPageResult =  await store.dispatch(ActionTypes.GET_INFO_PAGE_BY_CODE, "etusivu");
        newsPage.value = newsPageResult;

      } catch(err) {
        if (typeof err === 'string' || err instanceof String){
          showError(err as any, "virhe");
        }
      }
    });
 
    onMounted(async () => {
      console.log("onMounted");
      await refresh(false);

      document.addEventListener("visibilitychange",visibilityChanged);
    });

    onUnmounted(async ()=>{
      document.removeEventListener("visibilitychange",visibilityChanged);
    });


    const showFamilyMemberSelect = async () => {
      const modal = await modalController
        .create({
          component: SelectFamilyMemberModal,
          cssClass: 'my-custom-class',
          componentProps: {
            title: 'New Title'
          },
        });
      return modal.present();
    }

    const selectOrganisation = (item: OrganizationInfo) => {
      store.dispatch(ActionTypes.SELECT_ORG, item);
    };

    const unRegisterEvent = (reservation: ReservationViewModel) => {
      calendarStore.removeReservation(reservation.id)
        .catch(r=>{ showError(r, "virhe"); });   
    };

    const links = [{
      'text': 'Hippa',
      'src': '/assets/HippaLogo.png',
      'href': 'https://hippa.fi/'
    },
    {
      'text': 'Polte',
      'src': '/assets/PolteLogo.png',
      'href': 'https://www.poltesali.fi/'
    }];

    const refreshNow = async (item: any) => {
 
      calendarStore.refreshVisits()
        .then()
        .catch((err) => {
          showError(err, "virhe");
      });

      store.dispatch(ActionTypes.REFRESH_FULL_PROFILE,true)
      .then()
        .catch((err) => {
          showError(err, "virhe");
      });

      calendarStore.refreshReservations()
      .then()
        .catch((err) => {
          showError(err, "virhe");
      });

      openShoppingCarts.value = await financialStore.getUserCartListOpen(userId.value);

      item.target.complete();
    };
    
    return {
      router,
      add,
      calendar,
      cartOutline,receiptOutline,logInOutline,
      cart,
      links,
      hammerOutline, 
      happyOutline,
      peopleOutline,
      organisation,
      userId,
      unRegisterEvent,
      selectOrganisation,
      showFamilyMemberSelect, openShoppingCarts,
      personCircleOutline,
      familyMember,
      momentjs,
      reservations,
      refreshNow,
      visits,
      newsPage,
      fullProfile,
    }
  },
  });
