import {
    createStore,
    MutationTree,
    ActionContext,
    ActionTree,
    GetterTree,
    Store as VuexStore,
    CommitOptions,
    DispatchOptions,
    createLogger
  } from "vuex"; 
  
import axios, { AxiosError, AxiosResponse } from "axios";
import { OrganizationInfo } from "@/models/Organization";
import createPersistedState from "vuex-persistedstate"
import { FamilyMemberDetailedViewModel, UserCategoryFullInfo, UserDetailedViewModel, UserProfileViewModel } from "@/models/UserDetailedViewModel";
import { EventInstanceViewModel, PublicProfileViewModel } from "@/models/EventInstanceViewModel";
import { ListResponse, RequestResponse } from "@/models/UserSearchModel";
import { ProductViewModel } from "@/models/ProductViewModel";
import { ReturnedStatusUpdateRequest, ShoppingCart, ShoppingCartRequest, ShoppingCartResponse, ShoppingCartRow, ShoppingCartSimpleViewModel } from "@/models/ShoppingCart";
import { PaymentFullModel, PaymentSelectModel } from "@/models/PaymentModels";
import { ReservationViewModel } from "@/models/ReservationViewModel";
import { TicketInfo } from "@/models/Ticket";
import * as signalR from "@microsoft/signalr";
import { SqlRequestModel } from "@/models/SqlRequestModel";
import { InfoPageListModel, InfoPageListReponse, InfoPageModel } from "@/models/InfoPageModel";

  export type ListItem = {id: number; name: string ; description: string ; counter: number; checked: boolean };

  export type UserLogin = {username: string; password: string}
  export type ResetPasswordModel = {token: string; password: string}

  export type State = {
    counter: number; 
    cart: ShoppingCart;
    organization: OrganizationInfo;
    items: ListItem[];
    fullProfile: UserDetailedViewModel|undefined;
    familyMember: FamilyMemberDetailedViewModel|undefined;
    hiddenTypes: string[];
    favoriteTypes: string[];
    theme: string|undefined;
  };


  export const prettyResponse = ( reason: any, parseError: undefined|((message: string) => string) ) => {
    const errorResponse = reason as AxiosError;
    if(!errorResponse.isAxiosError){
      return reason;
    } else {
      let message: any = errorResponse.response?.data;
      
      if(!message || message === "")
        message = errorResponse.message;

      if(message?.type==="https://tools.ietf.org/html/rfc7231#section-6.5.1"){
        message = message.title + ": " + JSON.stringify(message.errors);}
        if(message?.message){ message = message.message; }

        if(parseError)
          return parseError(message as string);

        return(message ?? "");
    }
  }

  
  //set state
  const state: State = {
    counter: 0 , 
    organization:{id:"",name:"", isVisible:true},
    cart: new ShoppingCart(),
    items: [
    {id:0, name:"name", description:"desc", counter:0, checked:true },
    {id:1, name:"name2", description:"desc2", counter:2, checked:false }
    ],
    fullProfile: undefined,
    familyMember: undefined,
    hiddenTypes: [],
    favoriteTypes: [],
    theme: undefined,
  };
  
export function getDomain () {
  if(location.hostname == "oma.poltesali.fi") 
    return `${process.env.VUE_APP_POLTE_SERVER_ADDRESS}`;

  if(location.hostname == "omatest.hippa.fi")
    return `${process.env.VUE_APP_TEST_SERVER_ADDRESS}`;

  return `${process.env.VUE_APP_HIPPA_SERVER_ADDRESS}`
}

export const postNow = (url: string, data: any) => {
  const auth = useAuthStore();
  return axios.post(`${getDomain()}/${url}`, data, 
  { headers: { Authorization: 'Bearer ' + auth.authToken }});
}

export const getNow = (url: string, params: URLSearchParams|undefined) => {
  const auth = useAuthStore();
  return axios.get(`${getDomain()}/${url}`, 
  {headers: { Authorization: 'Bearer ' + auth.authToken }, params});
}

export const deleteNow = (url: string, params: URLSearchParams|undefined) => {
  const auth = useAuthStore();
  return axios.delete(`${getDomain()}/${url}`, 
  { headers: { Authorization: 'Bearer ' + auth.authToken }, params});
}

export const patchNow = (url: string, data: any) => {
  const auth = useAuthStore();
  return axios.patch(`${getDomain()}/${url}`, data, 
  { headers: { Authorization: 'Bearer ' + auth.authToken }});
}

export const putNow = (url: string, data: any) => {
  const auth = useAuthStore();
  return axios.put(`${getDomain()}/${url}`, data, 
  { headers: { Authorization: 'Bearer ' + auth.authToken }});
}
  
  // mutations and action enums
  export enum MutationTypes {
    SELECT_ORG = "SELECT_ORG",
    SELECT_THEME = "SELECT_THEME",
    UPDATE_CART = "UPDATE_CART",
    UPDATE_FULL_PROFILE = "UPDATE_FULL_PROFILE",
    SELECT_FAMILYMEMBER = "SELECT_FAMILYMEMBER",
    TOGGLE_FAVORITE = "TOGGLE_FAVORITE",
    TOGGLE_HIDDEN = "TOGGLE_HIDDEN",
  }
    
  export type Mutations<S = State> = {
    [MutationTypes.SELECT_ORG](state: S, payload: OrganizationInfo): void;
    [MutationTypes.SELECT_THEME](state: S, payload: string|undefined): void;
    [MutationTypes.UPDATE_CART](state: S, payload:  ShoppingCart): void;
    [MutationTypes.UPDATE_FULL_PROFILE](state: S, payload:  UserDetailedViewModel|undefined): void;
    [MutationTypes.SELECT_FAMILYMEMBER](state: S, payload:  FamilyMemberDetailedViewModel|undefined): void;
    [MutationTypes.TOGGLE_FAVORITE](state: S, payload: string): void;
    [MutationTypes.TOGGLE_HIDDEN](state: S, payload: string): void;
  };
  
  const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.TOGGLE_FAVORITE](state: State, payload: string) {
      const index = state.favoriteTypes.indexOf(payload);
      if(index >= 0) {
        state.favoriteTypes.splice(index);
        return;
      }
      state.favoriteTypes.push(payload);
    },
    [MutationTypes.TOGGLE_HIDDEN](state: State, payload: string) {
      const index = state.hiddenTypes.indexOf(payload);
      if(index >= 0) {
        state.hiddenTypes.splice(index);
        return;
      }
      state.hiddenTypes.push(payload);
    },

    [MutationTypes.UPDATE_FULL_PROFILE](state: State, payload: UserDetailedViewModel) {
      const auth = useAuthStore();
      state.fullProfile = payload;

      // Update also organisations
      if(payload?.organisations && payload.organisations.length > 0){
        auth.organizations = payload.organisations;
      }

      if(payload?.familyMembers && payload.familyMembers.length > 0){
        // Try to select same familymember as before
        if(state.familyMember && state.familyMember.id){
          const fm = payload.familyMembers.find(i=>i.id === state?.familyMember?.id);
          if(fm) {
            state.familyMember = fm;
            return;
          }
        } 

        // Just select first one
        state.familyMember = payload?.familyMembers[0];
      }              
    },
    [MutationTypes.SELECT_FAMILYMEMBER](state: State, payload: FamilyMemberDetailedViewModel) {
      state.familyMember = payload;
    },
    [MutationTypes.SELECT_ORG](state: State, payload: OrganizationInfo) {      
          state.organization = payload;
          useTicketStore().organisationChanged();
    },
    [MutationTypes.SELECT_THEME](state: State, payload: string|undefined) {
      state.theme = payload;
},
[MutationTypes.UPDATE_CART](state: State, payload: ShoppingCart) {
          state.cart = payload;
    }
  };
  



  type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, State>, "commit">;

   //actions
   export enum ActionTypes {
    // Register & signin to event
    IS_RESERVE_ALLOWED = "IS_RESERVE_ALLOWED",
    LIST_RESERVATIONS = "LIST_RESERVATIONS",
    IS_VISIT_ALLOWED = "IS_VISIT_ALLOWED",

    // Calendar & events
    GET_COLLECTIONS = "GET_COLLECTIONS",
    UPDATE_COLLECTION = "UPDATE_COLLECTION",
    GET_COLLECTION = "GET_COLLECTION",

    // User profiles
    REFRESH_FULL_PROFILE = "REFRESH_FULL_PROFILE",
    GET_FULL_PROFILE = "GET_FULL_PROFILE",
    UPDATE_FULL_PROFILE = "UPDATE_FULL_PROFILE",
    SELECT_FAMILYMEMBER = "SELECT_FAMILYMEMBER",
    SEARCH_USERS = "SEARCH_USERS",
    COMBINE_USER = "COMBINE_USER",
    REMOVE_USER = "REMOVE_USER",
    REMOVE_USER_CATEGORY = "REMOVE_USER_CATEGORY",
    REMOVE_FAMILY_MEMBER = "REMOVE_FAMILY_MEMBER",

    // Products
    GET_PRODUCT = "GET_PRODUCT",
    UPDATE_PRODUCT = "UPDATE_PRODUCT",

    // Shopping cart & checkout
    REFRESH_CART = "REFRESH_CART",
    GET_PAYMENT_OPTIONS = "GET_PAYMENT_OPTIONS",
    GET_PAYMENT_FULL_MODEL = "GET_PAYMENT_FULL_MODEL",
    PAYMENT_OK = "PAYMENT_OK",
    MERGE_SHOPPING_CART = "MERGE_SHOPPING_CART",
    EMAIL_SHOPPING_CART = "EMAIL_SHOPPING_CART",
    GENERATE_CART = "GENERATE_CART",


    REGISTER_TO_EVENT_TEMPLATE = "REGISTER_TO_EVENT_TEMPLATE",


    SELECT_ORG = "SELECT_ORG",
    SELECT_THEME = "SELECT_THEME",
    ADD_TO_CART = "ADD_TO_CART",
    REMOVE_ROW = "REMOVE_ROW",
    GET_ORGANIZATIONS = "GET_ORGANIZATIONS",
    UPDATE_TICKET = "UPDATE_TICKET",
    CONNECTION_OPENED = "CONNECTION_OPENED",
    CONNECTION_CLOSED = "CONNECTION_CLOSED",
    CONNECTION_ERROR = "CONNECTION_ERROR",
    USER_OF_KEY = "USER_OF_KEY",
    GET_TICKETS_OF_USER = "GET_TICKETS_OF_USER",
    GET_EVENTS_OF_TICKET = "GET_EVENTS_OF_TICKET",
    LIST_SQL_REQUESTS = "LIST_SQL_REQUESTS",
    GET_USER_CATEGORIES = "GET_USER_CATEGORIES",
    GET_PUBLIC_PROFILES = "GET_PUBLIC_PROFILES",
    GET_CATEGORY = "GET_CATEGORY",
    UPDATE_CATEGORY = "UPDATE_CATEGORY",
    GET_INFO_PAGES = "GET_INFO_PAGES",
    GET_INFO_PAGE_BY_ID = "GET_INFO_PAGE_BY_ID",
    GET_INFO_PAGE_BY_CODE = "GET_INFO_PAGE_BY_CODE",
    UPDATE_INFO_PAGE = "UPDATE_INFO_PAGE",
  }   

  export interface Actions {
    [ActionTypes.SELECT_ORG]( { commit }: AugmentedActionContext, payload: OrganizationInfo): Promise<boolean>;
    [ActionTypes.SELECT_THEME]( { commit }: AugmentedActionContext, payload: string): Promise<boolean>;
    [ActionTypes.ADD_TO_CART]( { commit }: AugmentedActionContext, payload: ShoppingCartRow): Promise<boolean>;
    [ActionTypes.REFRESH_CART]( { commit }: AugmentedActionContext): Promise<ShoppingCart|null>;
    [ActionTypes.REMOVE_ROW]( { commit }: AugmentedActionContext, payload: ShoppingCartRow): Promise<ShoppingCart>;
    [ActionTypes.REFRESH_FULL_PROFILE]( { commit }: AugmentedActionContext, payload: boolean): Promise<UserDetailedViewModel>;
    [ActionTypes.GET_FULL_PROFILE]( { commit }: AugmentedActionContext,payload: number ): Promise<UserDetailedViewModel>;
    [ActionTypes.UPDATE_FULL_PROFILE]( { commit }: AugmentedActionContext,payload: UserDetailedViewModel ): Promise<UserDetailedViewModel>;
    [ActionTypes.SELECT_FAMILYMEMBER]( { commit }: AugmentedActionContext, payload: FamilyMemberDetailedViewModel): Promise<boolean>;
    [ActionTypes.GET_COLLECTIONS]( { commit }: AugmentedActionContext, payload: string): Promise<Array<CollectionDto>>;
    [ActionTypes.GET_PAYMENT_OPTIONS]( { commit }: AugmentedActionContext): Promise<PaymentSelectModel[]>;
    [ActionTypes.GET_PAYMENT_FULL_MODEL]( { commit }: AugmentedActionContext, payload: ShoppingCartRequest): Promise<PaymentFullModel|undefined>;
    [ActionTypes.GET_ORGANIZATIONS]( { commit }: AugmentedActionContext): Promise<Array<OrganizationInfo>>;
    [ActionTypes.GET_PRODUCT]( { commit }: AugmentedActionContext,payload: string): Promise<ProductViewModel>;
    [ActionTypes.UPDATE_PRODUCT]( { commit }: AugmentedActionContext,payload: ProductViewModel): Promise<ProductViewModel>;
    [ActionTypes.PAYMENT_OK]( { commit }: AugmentedActionContext): Promise<boolean>;
    [ActionTypes.GENERATE_CART]( { commit }: AugmentedActionContext, payload: number): Promise<ShoppingCartSimpleViewModel>;  
    [ActionTypes.SEARCH_USERS]( { commit }: AugmentedActionContext, payload: {search: string; categoryId: string|undefined}): Promise<ListResponse<UserProfileViewModel>|undefined>;
    [ActionTypes.UPDATE_TICKET]( { commit }: AugmentedActionContext, payload: TicketInfo): Promise<TicketInfo>;
    [ActionTypes.MERGE_SHOPPING_CART]( { commit }: AugmentedActionContext, payload: {source: string; target: string}): Promise<ShoppingCartSimpleViewModel>;
    [ActionTypes.EMAIL_SHOPPING_CART]( { commit }: AugmentedActionContext, payload: string): Promise<boolean>;
    [ActionTypes.REMOVE_USER]( { commit }: AugmentedActionContext, payload: number): Promise<boolean>;
    [ActionTypes.REMOVE_USER_CATEGORY]( { commit }: AugmentedActionContext, payload: string): Promise<boolean>;
    [ActionTypes.REMOVE_FAMILY_MEMBER]( { commit }: AugmentedActionContext, payload: {userId: number; familyMemberId: string}): Promise<boolean>;
    [ActionTypes.COMBINE_USER]( { commit }: AugmentedActionContext, payload: {id1: number; id2: number}): Promise<boolean>;
    [ActionTypes.USER_OF_KEY]( { commit }: AugmentedActionContext, payload: string):  Promise<number>;
    [ActionTypes.REGISTER_TO_EVENT_TEMPLATE]( { commit }: AugmentedActionContext, payload: string):  Promise<RegisterEventTemplate>;    
    [ActionTypes.GET_TICKETS_OF_USER]( { commit }: AugmentedActionContext, payload: {userId: number; organization: string}):  Promise<Array<TicketInfo>>;
    [ActionTypes.GET_EVENTS_OF_TICKET]( { commit }: AugmentedActionContext, payload: {userId: number; ticketId: string; organizationId: string} ): Promise<Array<EventInstanceViewModel>>;
    [ActionTypes.LIST_SQL_REQUESTS]( { commit }: AugmentedActionContext): Promise<Array<SqlRequestModel>>;
    [ActionTypes.GET_USER_CATEGORIES]( { commit }: AugmentedActionContext): Promise<Array<UserCategoryFullInfo>>;
    [ActionTypes.GET_PUBLIC_PROFILES]( { commit }: AugmentedActionContext): Promise<Array<PublicProfileViewModel>>;
    [ActionTypes.GET_CATEGORY]( { commit }: AugmentedActionContext, payload: string): Promise<UserCategoryFullInfo>;
    [ActionTypes.UPDATE_CATEGORY]( { commit }: AugmentedActionContext, payload: UserCategoryFullInfo): Promise<UserCategoryFullInfo>;
    [ActionTypes.IS_VISIT_ALLOWED]( { commit }: AugmentedActionContext, payload: {userId: number; memberId: string; eventInstanceId: string; reservationId: string|undefined; later: boolean}): Promise<string>;
    [ActionTypes.IS_RESERVE_ALLOWED]( { commit }: AugmentedActionContext, payload: {userId: number; memberId: string; eventInstanceId: string}): Promise<boolean>;
    [ActionTypes.LIST_RESERVATIONS]( { commit }: AugmentedActionContext, payload: {userId: number; from: Date|undefined; to: Date|undefined} ): Promise<Array<ReservationViewModel>>;
    [ActionTypes.GET_INFO_PAGES]( { commit }: AugmentedActionContext): Promise<Array<InfoPageListModel>>;
    [ActionTypes.GET_INFO_PAGE_BY_CODE]( { commit }: AugmentedActionContext, payload: string): Promise<InfoPageModel>;
    [ActionTypes.GET_INFO_PAGE_BY_ID]( { commit }: AugmentedActionContext, payload: string): Promise<InfoPageModel>;
    [ActionTypes.UPDATE_INFO_PAGE]( { commit }: AugmentedActionContext, payload: InfoPageModel): Promise<InfoPageModel>;
  }
  
  export const actions: ActionTree<State, State> & Actions = {
   
    [ActionTypes.REFRESH_CART]({commit,state}) {
      return new Promise((resolve,reject) => {
        const auth = useAuthStore();

        const data = state.cart.toShoppingCartRequest(state.cart);
        axios
        .post(`${getDomain()}/shoppingCart/update`, data,
        {headers: {Authorization: 'Bearer ' + auth.authToken}})
        .then((result) => {          
          const typedResponse = result as AxiosResponse<ShoppingCartResponse>;   
          if(typedResponse.data.rows === null || typedResponse.data.rows.length === 0){
            commit(MutationTypes.UPDATE_CART, new ShoppingCart());
            resolve(new ShoppingCart());
          } else if(typedResponse.data.state === "CLOSED" || typedResponse.data.state === "PENDING_INVOICE"){
            commit(MutationTypes.UPDATE_CART, new ShoppingCart());
            reject("closed");
          } else {
            const cart = new ShoppingCart();
            cart.fromResult(typedResponse.data);
            commit(MutationTypes.UPDATE_CART, cart);
            resolve(cart); 
          }
        })
        .catch ((reason) => {
          //Not found!
          reject(prettyResponse(reason,(message)=>{
            switch(message){
              case "Not found!":
                commit(MutationTypes.UPDATE_CART, new ShoppingCart());
                return "Ostoskoria ei enää löydy.";
                default:
                  return message;
            }
          }));
        });
      });
  },


  [ActionTypes.GET_PAYMENT_FULL_MODEL]({state}, payload: ShoppingCartRequest) {
    return new Promise((resolve,reject) => {
      const auth = useAuthStore();

      //const data = state.cart.toShoppingCartRequest(state.cart);
      axios
      .post(`${getDomain()}/shoppingCart/PaymentFullModel`, payload,
      {headers: {Authorization: 'Bearer ' + auth.authToken}})
      .then((result) => {
        const typedResponse = result as AxiosResponse<PaymentFullModel>;
        if(typedResponse.data.redirectNeeded && (typedResponse.data.buttons === null || typedResponse.data.buttons.length === 0)){
          reject("Maksutapoja ei löytynyt.");
        } else {
          resolve(typedResponse.data);
        }
      })
      .catch ((reason) => {
        reject(prettyResponse(reason,(message)=>{
          switch(message){
            case "type lasku not found":
              return "Toimitustapa lasku ei ole käytettävissä.";
              default:
                return message;
          }
        }));

      });
    });
},

[ActionTypes.PAYMENT_OK]({dispatch,commit,state}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

    const data = new ReturnedStatusUpdateRequest();
    data.id = state.cart.id ?? "";
    axios
    .post(`${getDomain()}/shoppingCart/ReturnedStatusUpdate`, data,
    {headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((result) => {
      const typedResponse = result as AxiosResponse<boolean>;

      if(typedResponse.data){
        commit(MutationTypes.UPDATE_CART, new ShoppingCart());
        dispatch(ActionTypes.REFRESH_FULL_PROFILE,undefined);
      }
      resolve(typedResponse.data);
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},

[ActionTypes.GENERATE_CART]({state}, payload: number) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();


    if(!payload)
      reject("userId not selected!");

  const params = new URLSearchParams([['userId', payload.toString()]]);
    
  axios.get(`${getDomain()}/ShoppingCart/GenerateCart`,{headers: {Authorization: 'Bearer ' + auth.authToken}, params})
  .then((response: AxiosResponse<ShoppingCartSimpleViewModel>) => {
      if(response.data && response.data.id){
        resolve(response.data);                 
      } else {
        reject(response.data);
      }
  })
  .catch(reason => {
    reject(prettyResponse(reason,(message)=>{
      switch(message){
        case "No monthly subscriptions!":
          return "Ei kuukausilaskutettavaa!";
        default:
          return message;
      }
    }));

  });

  });
},


[ActionTypes.GET_ORGANIZATIONS]({state}) {
  return new Promise((resolve, reject) => {      
    const auth = useAuthStore();

    axios.get(`${getDomain()}/Organization`,{headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response: AxiosResponse<ListResponse<OrganizationInfo>>) => {
        if(response.data.success===true){
          resolve(response.data.items);                 
        } else {
          reject(response.data.message);
        }
    })
    .catch(reason => {
      reject(prettyResponse(reason,undefined));
    });  
});
},
[ActionTypes.GET_PRODUCT]({state}, payload: string) {
  return new Promise((resolve, reject) => {
    const auth = useAuthStore();


    if(!payload)
      reject("id not selected");

    const params = new URLSearchParams([['id', payload]]);
      
    axios.get(`${getDomain()}/product/details`,{headers: {Authorization: 'Bearer ' + auth.authToken}, params})
    .then((response: AxiosResponse<ProductViewModel>) => {
        if(response.data.id){
          resolve(response.data);                 
        } else {
          reject(response.data);
        }
    })
    .catch(reason => {
      reject(prettyResponse(reason,undefined));
    });  
});
},

[ActionTypes.GET_CATEGORY]({state}, payload: string) {
  return new Promise((resolve, reject) => {
    const auth = useAuthStore();

    const params = new URLSearchParams([['id', payload]]);
      
    axios.get(`${getDomain()}/UserProfile/GetCategory`,{headers: {Authorization: 'Bearer ' + auth.authToken}, params})
    .then((response: AxiosResponse<UserCategoryFullInfo>) => {
        if(response.data.id){
          resolve(response.data);                 
        } else {
          reject(response.data);
        }
    })
    .catch(reason => {
      reject(prettyResponse(reason,undefined));
    });  
});
},


[ActionTypes.GET_COLLECTIONS]({state}, payload: string) {
  return new Promise((resolve, reject) => {
    const auth = useAuthStore();


    const params = new URLSearchParams([['type', payload]]);
      
    axios.get(`${getDomain()}/collection/list`,{headers: {Authorization: 'Bearer ' + auth.authToken}, params})
    .then((response: AxiosResponse<Array<CollectionDto>>) => {
      resolve(response.data);                 
    })
    .catch(reason => {
      reject(prettyResponse(reason,undefined));
    });  
});
},

[ActionTypes.UPDATE_TICKET]({state}, payload: TicketInfo) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     console.log(payload);
     console.log(JSON.stringify(payload));
    axios
    .post(`${getDomain()}/ticket/details`, payload,
    {headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response: AxiosResponse<TicketInfo>) => {
       if(response.data.id){
        resolve(response.data);                 
      } else {
        reject(response.data);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},


[ActionTypes.GET_TICKETS_OF_USER]({state}, payload: {userId: number; organization: string}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     console.log(payload);
     const params = new URLSearchParams([ ['userId', payload.userId.toString()],
                                          ['organization', payload.organization]]);
   
    axios
    .get(`${getDomain()}/ticket/ActiveTickets`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<Array<TicketInfo>>) => {

      if(response.data.length > 0){
        const validatedResponse = new Array<TicketInfo>();
        response.data.forEach(i=>validatedResponse.push((new TicketInfo).fromResponse(i)));

        validatedResponse.sort((i,j)=>('' + (i.titleString ?? "")).localeCompare(j.titleString ??""));
        resolve(validatedResponse);             
      } else {
        reject("Kortteja ei löytynyt")
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},

[ActionTypes.LIST_RESERVATIONS]({state}, payload: {userId: number; from: Date|undefined; to: Date|undefined}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

    console.log(payload);
    const params = new URLSearchParams([ ['userId', payload.userId.toString()]]);
   
    if(payload.from)
      params.append('from',payload.from.toJSON());

    if(payload.to)
      params.append('to',payload.to.toJSON());
      
    axios
    .get(`${getDomain()}/reservation/List`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<Array<ReservationViewModel>>) => {

      if(response.data.length > 0){
        const validatedResponse = new Array<ReservationViewModel>();
        response.data.forEach(i=>validatedResponse.push((new ReservationViewModel).fromResponse(i)));
        validatedResponse.sort((a,b)=>(a.eventInstanceBeginTime ?? new Date()).getTime()-(b.eventInstanceBeginTime ?? new Date()).getTime());
        resolve(validatedResponse);             
      } else {
        resolve([]);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},


[ActionTypes.MERGE_SHOPPING_CART]({state}, payload: {source: string; target: string}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     console.log(payload);
     const params = new URLSearchParams([ ['id', payload.source],
                                          ['id2', payload.target],]);
   
    axios
    .get(`${getDomain()}/shoppingcart/merge`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<ShoppingCartSimpleViewModel>) => {
       if(response.status == 200 && response.data.id){
        resolve(response.data);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "Status error":
            return "Tässä tilassa olevaa ostosta ei voi poistaa.";
            default:
              return message;
        }
      }));
    });
  });
},
 
[ActionTypes.EMAIL_SHOPPING_CART]({state}, payload: string) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     console.log(payload);
     const params = new URLSearchParams([ ['cartId', payload]]);
   
    axios
    .get(`${getDomain()}/shoppingcart/EmailInvoice`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse) => {
       if(response.status == 200){
        resolve(true);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "Status error":
            return "Tässä tilassa olevaa ostosta ei voi poistaa.";
            default:
              return message;
        }
      }));

    });
  });
},
[ActionTypes.REMOVE_USER]({state}, payload: number) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     console.log(payload);
     const params = new URLSearchParams([ ['id', payload.toString()]]);
   
    axios
    .get(`${getDomain()}/userprofile/remove`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse) => {
       if(response.status == 200){
        resolve(true);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "Status error":
            return "Tässä tilassa olevaa ostosta ei voi poistaa.";
            default:
              return message;
        }
      }));

    });
  });
},
[ActionTypes.REMOVE_USER_CATEGORY]({state}, payload: string) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     console.log(payload);
     const params = new URLSearchParams([ ['id', payload.toString()]]);
   
    axios
    .get(`${getDomain()}/userprofile/RemoveCategory`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse) => {
      // debugger;
       if(response.status == 200){
        resolve(true);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "Status error":
            return "Tässä tilassa olevaa ostosta ei voi poistaa.";
            default:
              return message;
        }
      }));

    });
  });
},
[ActionTypes.REMOVE_FAMILY_MEMBER]({state}, payload: {userId: number; familyMemberId: string}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     console.log(payload);
     const params = new URLSearchParams([ ['userId', payload.userId.toString()],  
                                          ['familyMemberId', payload.familyMemberId.toString()]]);
   
    axios
    .get(`${getDomain()}/userprofile/RemoveFamilyMember`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse) => {
       if(response.status == 200){
        resolve(true);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "Status error":
            return "Tässä tilassa olevaa ostosta ei voi poistaa.";
            default:
              return message;
        }
      }));

    });
  });
},

[ActionTypes.COMBINE_USER]({state}, payload: {id1: number; id2: number}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     console.log(payload);
     const params = new URLSearchParams([ ['id1', payload.id1.toString()],  
                                          ['id2', payload.id2.toString()]]);
   
    axios
    .get(`${getDomain()}/userprofile/combine`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse) => {
       if(response.status == 200){
        resolve(true);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "Status error":
            return "Tässä tilassa olevaa ostosta ei voi poistaa.";
            default:
              return message;
        }
      }));

    });
  });
},



[ActionTypes.LIST_SQL_REQUESTS]({state}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

    const params = new URLSearchParams([ ['type','all']]);

    axios
    .get(`${getDomain()}/SqlRequest/List`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<Array<SqlRequestModel>>) => {
       if(response.status == 200 && response.data.length>0){
        resolve(response.data);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},

[ActionTypes.GET_USER_CATEGORIES]({state}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

    const params = new URLSearchParams([ ['type','all']]);

    axios
    .get(`${getDomain()}/UserProfile/ListCategories`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<Array<UserCategoryFullInfo>>) => {
       if(response.status == 200 && response.data.length>0){
        resolve(response.data);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},
[ActionTypes.GET_INFO_PAGES]({state}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

    axios
    .get(`${getDomain()}/InfoPage`, {headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response: AxiosResponse<InfoPageListReponse>) => {
       if(response.status == 200 && response.data.length>0){
        resolve(response.data);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},
[ActionTypes.GET_PUBLIC_PROFILES]({state}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

    const params = new URLSearchParams([ ['type','all']]);

    axios
    .get(`${getDomain()}/PublicProfile`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<Array<PublicProfileViewModel>>) => {
       if(response.status == 200 && response.data.length>0){
        resolve(response.data);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},

[ActionTypes.GET_EVENTS_OF_TICKET]({state}, payload: {userId: number; ticketId: string; organizationId: string}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     const params = new URLSearchParams([ ['userId', payload.userId.toString()],
                                          ['ticketId', payload.ticketId],
                                          ['organizationId', payload.organizationId]]);
    axios
    .get(`${getDomain()}/EventInstance/SearchOptions`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<Array<EventInstanceViewModel>>) => {
       if(response.status == 200 && response.data.length>0){
        const validatedResponse = new Array<EventInstanceViewModel>();
        response.data.forEach(i=>{
            validatedResponse.push((new EventInstanceViewModel).fromResponse(i));
        });
      
        resolve(validatedResponse); 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},
[ActionTypes.IS_RESERVE_ALLOWED]({state}, payload: {userId: number; memberId: string; eventInstanceId: string; }) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     const params = new URLSearchParams([ ['userId', payload.userId.toString()],
                                          ['memberId', payload.memberId],
                                          ['eventInstanceId', payload.eventInstanceId]]);
    axios
    .get(`${getDomain()}/EventInstance/IsReserveAllowed`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<boolean>) => {
        resolve(response.data); 
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},
[ActionTypes.IS_VISIT_ALLOWED]({state}, payload: {userId: number; memberId: string; eventInstanceId: string; reservationId: string|undefined; later: boolean}) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();

     const params = new URLSearchParams([ ['userId', payload.userId.toString()],
                                          ['memberId', payload.memberId],
                                          ['reservationId', payload.reservationId ?? ""],
                                          ['eventInstanceId', payload.eventInstanceId],
                                          ['later',payload.later.toString()]]);
    axios
    .get(`${getDomain()}/EventInstance/IsSignInAllowed`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<string>) => {
        resolve(response.data); 
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},

[ActionTypes.GET_INFO_PAGE_BY_CODE]({state}, payload: string) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();
     console.log(payload);
     const params = new URLSearchParams([['code', payload]]);
   
    axios
    .get(`${getDomain()}/InfoPage/Details`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<InfoPageModel>) => {
       if(response.data.id){
        resolve(response.data);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},
[ActionTypes.GET_INFO_PAGE_BY_ID]({state}, payload: string) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();
     console.log(payload);
     const params = new URLSearchParams([['id', payload]]);
   
    axios
    .get(`${getDomain()}/InfoPage/Details`, {headers: {Authorization: 'Bearer ' + auth.authToken},params})
    .then((response: AxiosResponse<InfoPageModel>) => {
       if(response.data.id){
        resolve(response.data);                 
      } else {
        reject(response.statusText);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},

[ActionTypes.UPDATE_PRODUCT]({state}, payload: ProductViewModel) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();
     console.log(payload);
    axios
    .post(`${getDomain()}/product/details`, payload,
    {headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response: AxiosResponse<ProductViewModel>) => {
       if(response.data.id){
        resolve(response.data);                 
      } else {
        reject(response.data);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},
[ActionTypes.UPDATE_INFO_PAGE]({state}, payload: InfoPageModel) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();
     console.log(payload);
    axios
    .post(`${getDomain()}/InfoPage/Details`, payload,
    {headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response: AxiosResponse<InfoPageModel>) => {
       if(response.data.id){
        resolve(response.data);                 
      } else {
        reject(response.data);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},

[ActionTypes.UPDATE_CATEGORY]({state}, payload: UserCategoryFullInfo) {
  return new Promise((resolve,reject) => {
    const auth = useAuthStore();
    axios
    .post(`${getDomain()}/UserProfile/SaveCategory`, payload,
    {headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response: AxiosResponse<UserCategoryFullInfo>) => {
       if(response.data.id){
        resolve(response.data);                 
      } else {
        reject(response.data);
      }
    })
    .catch ((reason) => {
      reject(prettyResponse(reason,undefined));
    });
  });
},

// TODO: Remove
[ActionTypes.GET_PAYMENT_OPTIONS]({state}) {
  return new Promise((resolve, reject) => {
    const auth = useAuthStore();
    axios.get(`${getDomain()}/ShoppingCart/PaymentOptions`,{headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response) => {
      const typedResponse = response as AxiosResponse<Array<PaymentSelectModel>>;
      if(typedResponse.data.length > 0){
        resolve(typedResponse.data);                 
      } else {
        reject("Not found");
      }
    })
    .catch(reason => {
      reject(prettyResponse(reason,undefined));
    });
});
},

[ActionTypes.SEARCH_USERS]({state}, payload: {search: string; categoryId: string|undefined}) {
  return new Promise((resolve, reject) => {
    const auth = useAuthStore();

    const params = new URLSearchParams([['search', payload.search]]);

    if(payload.categoryId){
      params.append('categoryId',payload.categoryId);
    }

    axios.get(`${getDomain()}/userprofile/QuickSearchUserInfoJson`,{headers: {Authorization: 'Bearer ' + auth.authToken}, params})
    .then((response ) => {
        const listResponse = response as AxiosResponse<ListResponse<UserProfileViewModel>>
        if(listResponse.data.success){
          resolve(listResponse.data);                 
        } else {
          reject(listResponse.data.message);
        }

    })
    .catch(reason => {
      reject(prettyResponse(reason,undefined));
    });
  
});
},
[ActionTypes.GET_FULL_PROFILE]({state}, payload: number) {
    return new Promise((resolve, reject) => {
      const auth = useAuthStore();
      //debugger;
      axios.get(`${getDomain()}/userprofile/detailsjson?id=${payload}`,{headers: {Authorization: 'Bearer ' + auth.authToken}})
      .then((response) => {
        const typedResponse = response as AxiosResponse<RequestResponse<UserDetailedViewModel>>
          if(typedResponse.data.success === true && typedResponse.data.item?.userId){
            resolve(typedResponse.data.item);                 
          } else {
            reject(typedResponse.data.message);
          }

      })
      .catch(reason => {
        reject(prettyResponse(reason,(message)=>{
          switch(message){
            case "userName is in use":
              return "Käyttäjätunnus tai sähköposti on jo käytössä.";
              default:
                return message;
          }
        }));
      });
    
  });
},
[ActionTypes.UPDATE_FULL_PROFILE]({state}, payload: UserDetailedViewModel) {
  return new Promise((resolve, reject) => {
    const auth = useAuthStore();
    const userStore = useUserStore();
   
    axios.post(`${getDomain()}/userprofile/detailsjson`,
    payload,
    {headers: {Authorization: 'Bearer ' + auth.authToken, Cid: userStore.srConnectionId ?? "-"}})
    .then((response) => {
      const typedResponse = response as AxiosResponse<RequestResponse<UserDetailedViewModel>>
        if(typedResponse.data.success === true && typedResponse.data.item?.userId){
          resolve(typedResponse.data.item);                 
        } else {
          reject(typedResponse.data.message);
        }

    })
    .catch(reason => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "userName is in use":
            return "Käyttäjätunnus tai sähköposti on jo käytössä.";
          case "Same card number can be only set to one family member!":
            return "Saman avaimen numeron voi laittaa vain yhdelle perheenjäsenelle!";
          case "Card number already in use by someone else!":
            return "Saman avaimen numero on jo käytössä jollakin toisella jäsenellä!";
          default:
            return message;
            }
      }));


    });
  
});
},
[ActionTypes.REFRESH_FULL_PROFILE]({commit, dispatch, state}, payload: boolean) {
  return new Promise((resolve, reject) => {
    const auth = useAuthStore();
     if(!payload && state.fullProfile){
       resolve(state.fullProfile);
     } else {
    axios.get(`${getDomain()}/userprofile/detailsjson?id=${auth.id}`,{headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response) => {
        if(response.data.item){
          const validatedResponse = (new UserDetailedViewModel()).fromResponse2(response.data.item);
          commit(MutationTypes.UPDATE_FULL_PROFILE, validatedResponse);

          // Reselect organization if needed
          if(validatedResponse.organisations && (!state.organization || !state.organization.id || 
            validatedResponse.organisations.findIndex(i=>i.id == state.organization.id) < 0)){
              dispatch(ActionTypes.SELECT_ORG, validatedResponse.organisations[0]);
            }

          resolve(validatedResponse);                 
        } else {
          reject(response.data.message);
        }

        
    })
    .catch(reason => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "Email not found":
            return "Käyttäjätunnusta tai sähköpostia ei löytynyt!";
            default:
              return message;
        }
      }));
    });
  }
});
},
[ActionTypes.USER_OF_KEY]({state}, payload: string) {
  return new Promise((resolve, reject) => {
    const auth = useAuthStore();

    axios.get(`${getDomain()}/userprofile/UserOfKey?code=${payload}`,{headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response: AxiosResponse<number>) => {
        if(response.data > 0){
          resolve(response.data);                 
        } else {
          reject("Avainta ei löytynyt!");
        }        
    })
    .catch(reason => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "User not found!":
            return "Avainta ei löytynyt!";
            default:
              return message;
        }
      }));
    });
  }
)
},

[ActionTypes.REGISTER_TO_EVENT_TEMPLATE]({state}, payload: string) {
  return new Promise((resolve, reject) => {
    const auth = useAuthStore();

    axios.get(`${getDomain()}/EventTemplate/RegisteringTo?id=${payload}`,{headers: {Authorization: 'Bearer ' + auth.authToken}})
    .then((response: AxiosResponse<RegisterEventTemplate>) => {
        if(response.status === 200){
          resolve(response.data);                 
        } else {
          reject(response.data);
        }        
    })
    .catch(reason => {
      reject(prettyResponse(reason,(message)=>{
        switch(message){
          case "User not found!":
            return "Avainta ei löytynyt!";
            default:
              return message;
        }
      }));
    });
  }
)
},

  [ActionTypes.SELECT_ORG]({commit}, payload: OrganizationInfo) {
    return new Promise((resolve) => {
        commit(MutationTypes.SELECT_ORG, payload);
        resolve(true);
    }); 
},
  [ActionTypes.SELECT_THEME]({commit}, payload: string|undefined) {
    return new Promise((resolve) => {
        commit(MutationTypes.SELECT_THEME, payload);
        resolve(true);
    }); 
},

[ActionTypes.SELECT_FAMILYMEMBER]({commit}, payload: FamilyMemberDetailedViewModel) {
  return new Promise((resolve) => {
      commit(MutationTypes.SELECT_FAMILYMEMBER, payload);
      resolve(true);
  }); 
},



    [ActionTypes.ADD_TO_CART]({state,commit}, payload: ShoppingCartRow) {
      return new Promise((resolve) => {
          state.cart.rows.push(payload);
          commit(MutationTypes.UPDATE_CART, state.cart);
          resolve(true);
      }); 
  },
  [ActionTypes.REMOVE_ROW]({dispatch,state,commit}, payload: ShoppingCartRow) {
    return new Promise((resolve) => {
      const ticketId = payload.ticketId;
      if(ticketId){
        let index = state.cart.rows.findIndex(i=>i.ticketId == ticketId)
        while (index > -1) { 
          state.cart.rows.splice(index, 1);
          index = state.cart.rows.findIndex(i=>i.ticketId == ticketId)
        }
      } else { 
        const index2 = state.cart.rows.findIndex(i=>i.id == payload.id)
        if (index2 > -1) {
          state.cart.rows.splice(index2, 1);
        }
      
      }

      commit(MutationTypes.UPDATE_CART, state.cart);
      dispatch(ActionTypes.REFRESH_CART, undefined);
      resolve(state.cart);
    }); 
},
  };
  


  // Getters types
  export type Getters = {
    doubleCounter(state: State): number;
    organisation(state: State): OrganizationInfo;
    cart(state: State): ShoppingCart;
    fullProfile(state: State): UserDetailedViewModel|undefined;
    familyMember(state: State): FamilyMemberDetailedViewModel|undefined;
  };
  
  export const getters: GetterTree<State, State> & Getters = {
    doubleCounter: state => {
      console.log("state", state.counter);
      return state.counter * 2;
    },
    organisation: state => {
      return state.organization;
    },
    cart: state => {
      return state.cart;
    },
    fullProfile: state => {
      return state.fullProfile;
    },
    familyMember: state => {
      return state.familyMember;
    },
  };
  



  //setup store type
  export type Store = Omit<VuexStore<State>, "commit" | "getters" | "dispatch"> & {
    commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
      key: K,
      payload: P,
      options?: CommitOptions
    ): ReturnType<Mutations[K]>;
  } & {
    getters: {
      [K in keyof Getters]: ReturnType<Getters[K]>;
    };
  } & {
    dispatch<K extends keyof Actions>(
      key: K,
      payload: Parameters<Actions[K]>[1],
      options?: DispatchOptions
    ): ReturnType<Actions[K]>;
  };

  export const client = new signalR.HubConnectionBuilder()
  .configureLogging(process.env.VUE_APP_SIGNALR_LOG_LEVEL)
  .withUrl(getDomain() + process.env.VUE_APP_SIGNALR_HUB_URL)
  .withAutomaticReconnect()
  .build();




  import { emitter } from "@/components/useEvents";
  import { RegisterEventTemplate } from "@/models/RegisterEventTemplate";
import { CollectionDto } from "@/models/Collections";
import { useUserStore } from "./userStore";
import { useAuthStore } from "./authStore";
import { useTicketStore } from "./ticketStore";
import { UserActionUpdate } from "@/models/UserActions";
import { useCalendarStore } from "./calendarStore";
import { calendar } from "ionicons/icons";

  export default function createWebSocketPlugin() {
    return (_store: any ) => {
  
      client.onclose(async (error)=>{
        const userStore = useUserStore();
        await userStore.srConnectionClosed(error);
      });

      client.onreconnected(async (_connectionId)=>{
        const userStore = useUserStore();
        const authStore = useAuthStore();
        await userStore.srConnectionOpened(client);
        await client.send("listenForActions", userStore.browserId, authStore.authToken);
      });

      client
        .start()
        .then(async () => {
          const userStore = useUserStore();
          const authStore = useAuthStore();
          await userStore.srConnectionOpened(client);
          await client.send("listenForActions", userStore.browserId, authStore.authToken);
        })
        .catch( async err => {
          const userStore = useUserStore();
          await userStore.srConnectionError(err);
        });

        client.on('receiveChatMessage', message => {
          console.log(message);
          emitter.emit("receiveChatMessage",message);
        });

        client.on('ping', _message => { let _a; });

        client.on('eventInstanceUpdated', message => {
          console.log(message);
          emitter.emit("eventInstanceUpdated",message);
        });

        client.on('updateMemberError', message => {
          console.log(message);
          emitter.emit("updateMemberError",message);
        });

        client.on('visibilityChanged', message => {
          console.log(message);
          // emitter.emit("updateMemberError",message);
        });        

        client.on('updateMemberDone', message => {
          console.log(message);
          emitter.emit("updateMemberDone",message);
        });

        client.on('userAction', async message => {
          const m = message as UserActionUpdate;
          const store = useStore();
          const calendarStore = useCalendarStore();

          if(store.state.fullProfile?.userId != m.userId)
            return;
            
            console.log(message);

            if(m.reserve){
              const r = calendarStore.reservations.find(i=>i.id == m.reserve?.reservationId);
              if(m.reserve.quantity == 0 && !r){

                // nothing to do

              } else if(!r){

                // reload reservation (will refresh at store also)
                await calendarStore.getReservation(m.reserve.reservationId);

              } else if(m.reserve.quantity == 0){

                // remove reservation
                calendarStore.removeReservationFromCache(m.reserve.reservationId);

              } else {

                // update reservation
                r.quantity = m.reserve.quantity;

              }
              emitter.emit("reserve",message);

            } else if(m.visit){
              const v = calendarStore.visits.find(i=>i.id == m.visit?.visitId);
              if(m.visit.quantity == 0 && !v){

                // nothing to do

              } else if(!v){

                // reload visit
                calendarStore.refreshVisits();

              } else if(m.visit.quantity == 0){

                // remove visit
                calendarStore.removeVisitFromCache(m.visit.visitId);

              }  else {

                // update visit
                v.quantity = m.visit.quantity;

              }
              emitter.emit("visit",message);
            }          

        });        
    };
    
  }

  export const store = createStore({
    state,
    mutations,
    actions,
    getters,
    plugins: [createLogger(),createPersistedState(), createWebSocketPlugin()]
  });
  

  export function useStore() {
    return store as Store;
  }

  class CurrentSessionObject {
    enteredRoute: string|undefined;
  }

  const currentSession = new CurrentSessionObject();

  export function useSessionObject() {
    return currentSession as CurrentSessionObject;
  }
