import { RemovableRef, StorageSerializers, useLocalStorage, useStorage } from "@vueuse/core";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { deleteNow, getNow, postNow, prettyResponse, useStore } from ".";
import { ListResponse, RequestResponse } from "@/models/UserSearchModel";
import { LocationCalendar } from "@/models/LocationCalendar";
import memoize from "memoizee";
import { LocationViewModel } from "@/models/LocationViewModel";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { EventTemplateListDto } from "@/models/EventTemplate";
import { EventInstanceViewModel } from "@/models/EventInstanceViewModel";
import { EventDescription } from "@/models/EventDescription";
import { NewReservationRequest, ReservationViewModel } from "@/models/ReservationViewModel";
import { useAuthStore } from "./authStore";
import { EventVisitsDetailedInfo, NewVisitViewModel } from "@/models/NewVisitModel";

const fn = function(location: string, urlParams: URLSearchParams) {
  return getNow(location,urlParams);
};

const getMemoized = memoize(fn, { 
  promise: true, 
  maxAge: 60000,
  normalizer: function(args) { return args[0] + "?" + (args[1] as URLSearchParams).toString();}
});

interface CalendarStoreState {
  calendarCollections: RemovableRef<string[]>;
  reservations: RemovableRef<Array<ReservationViewModel>>;
  reservationsRefreshed: Date | undefined;
  visits: RemovableRef<Array<NewVisitViewModel>>;
}

export const useCalendarStore = defineStore('calendar', {
    state: ():CalendarStoreState => ({
      calendarCollections: useLocalStorage("calendarCollections", [],{ serializer: StorageSerializers.object,  mergeDefaults: true  }),
      reservations: useStorage("reservations",[]),
      reservationsRefreshed: undefined,
      visits: useStorage("visits",[])
    }),
  
    getters: {
    }, 
  
    actions: {
      async getCalendar(organizationId: string | undefined, 
        locationId: string | undefined, 
        from: Date | undefined,
        days: number | undefined) {
        try {

          const params = new URLSearchParams([
            ['days', (days??1).toString()],
            ['from', (from??new Date()).toISOString()],]);
      
            if(locationId) params.append('locationId',locationId);      
            if(organizationId) params.append('organizationId',organizationId);
           
            const r = await getNow(`Calendar/get`, params) as AxiosResponse<RequestResponse<LocationCalendar>>;

            if(r.data.success===true) {
                return r.data.item;
              } else {
                throw new Error((r as any).data.Message);
              }
          } catch (reason) {
            throw (prettyResponse(reason,undefined));
          }
      },        

      async getCalendarForMemeber(
        organizationId: string | undefined, 
        locationId: string | undefined, 
        from: Date | undefined,
        days: number | undefined,
        userId: number | undefined,
        memberId: string | undefined,
        collections: Array<string> | undefined) {

          const params = new URLSearchParams([
            ['userId', (userId??0).toString()],
            ['memberId', (memberId??"")],
            ['days', (days??1).toString()],
            ['from', (from??new Date()).toISOString()],]);
      
            if(locationId) params.append('locationId',locationId);
            if(organizationId) params.append('organizationId',organizationId);
            if(collections) params.append('collections',collections.toString());
           
            const r = await getNow(`Calendar/ListForMember`, params) as AxiosResponse<RequestResponse<LocationCalendar>>;

            if(r.data.success===true) {
                return r.data.item;
              } else {
                throw new Error((r as any).data.Message);
              }
      },   

      async getLocation(id: string) {
        try {
            const params = new URLSearchParams([ ['id', id]]);
            const r = await getMemoized("Location/Get",params) as AxiosResponse<LocationViewModel>;

            if(r.data.id === id){
              return r.data;
            } else {
              throw "Not found";
            }

        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async getLocations(organizationId: string) {
            const params = new URLSearchParams([ ['organizationId', organizationId]]);
            const r = await getMemoized("Location/list",params) as AxiosResponse<Array<LocationViewModel>>;
            return r.data;

      },


      async getCategory(id: string) {
        try {
            const r = await getNow(`Categories/${id}`, undefined) as AxiosResponse<EventCategoryViewModel>;
            return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      refreshReservation(reservation: ReservationViewModel) {
        const authStore = useAuthStore();

          // Update the reservation in the store if it is for the current user
          if (reservation.userId === authStore.id) {
            const index = this.reservations.findIndex((i) => i.id === reservation.id);
            if (index > -1)
              this.reservations.splice(index, 1);
            this.reservations.push(reservation);
          }

      },

      removeReservationFromCache(id: string) {
        const index = this.reservations.findIndex((i) => i.id === id);
        if (index > -1)
          this.reservations.splice(index, 1);
      },

      async getReservation(id: string) {
        const params = new URLSearchParams([['id', id]]);
        const r = await getNow(`Reservation/Details`, params) as AxiosResponse<ReservationViewModel>;
        const r2 = (new ReservationViewModel).fromResponse(r.data);

        this.refreshReservation(r2);

        return r2;
      },

      async getReservations(userId: number) {
        const params = new URLSearchParams([['userId', userId.toString()]]);
        const response = await getNow(`Reservation/List`, params) as AxiosResponse<Array<ReservationViewModel>>;

        if (response.data.length == 0)
          return [];

        const validatedResponse = new Array<ReservationViewModel>();
        response.data.forEach(i => validatedResponse.push((new ReservationViewModel).fromResponse(i)));
        validatedResponse.sort((a, b) => (a.eventInstanceBeginTime ?? new Date()).getTime() - (b.eventInstanceBeginTime ?? new Date()).getTime());

        return validatedResponse;
      },

      async refreshReservations() {
        const authStore = useAuthStore();
        this.reservations = await this.getReservations(authStore.id);
        this.reservationsRefreshed = new Date();
        return this.reservations;
      },

      async addReservation(reservation: NewReservationRequest) {
        try {
          const raw = await postNow(`Reservation/Details`, reservation) as AxiosResponse<ReservationViewModel>;
          const r = (new ReservationViewModel()).fromResponse(raw.data);
          this.refreshReservation(r);
          return r;

        } catch (reason) {
          throw prettyResponse(reason, (message) => {
            switch (message) {
              case "2 per day only":
                return "Vain 2 varausta yhdelle päivälle";
              case "Can't be reserved":
                return "Ei varattavissa";
              case "No space":
                return "Ei enää tilaa";
              case "Can't be found!":
                return "Tuntia ei löydy enää";
              case "Already reserved":
                return "Samalle jäsenelle on jo varaus";
              default:
                return message;
            }
          });
        }
      },

      refreshVisit(visit: NewVisitViewModel) {
        const authStore = useAuthStore();
        const store = useStore();

        // Find the family member
        const fm = store.state.fullProfile?.familyMembers?.find((i)=>i.id===visit.memberId);
        if(!fm) return;

        // Update the visit in the store
        if (visit.id === authStore.id) {
          const index = this.visits.findIndex((i) => i.id === visit.id);
          if (index > -1)
            this.visits.splice(index, 1);
            this.visits.push(visit);
          }
      },

      removeVisitFromCache(id: string) {
        const index = this.visits.findIndex((i) => i.id === id);
        if (index > -1)
          this.visits.splice(index, 1);
      },

      async addVisit(visit: NewVisitViewModel) {
        try {
          const raw = await postNow(`Visit/PostVisit`, visit) as AxiosResponse<NewVisitViewModel>;
          const r = (new NewVisitViewModel()).fromResponse(raw.data);

          this.refreshVisit(r);
          return r;

        } catch (reason) {
          throw prettyResponse(reason, (message) => {
            switch(message){
              case "2 per day only":
                return "Vain 2 varausta yhdelle päivälle";
              default:
                return message;
            }  
          });
        }
      },

      async getEventVisits(eventInstanceId: string) {
        const params = new URLSearchParams([['id', eventInstanceId]]);
        const response = await getNow(`Visit/ListVisits`, params) as AxiosResponse<EventVisitsDetailedInfo>;

        return (new EventVisitsDetailedInfo()).fromResponse(response.data);
      },

      async removeVisit(id: string) {
        const params = new URLSearchParams([['id', id]]);
        await getNow(`Visit/RemoveVisit`, params);
        this.removeVisitFromCache(id);
      },

      async removeReservation(id: string) {

        try {
          const params = new URLSearchParams([['id', id]]);
          await getNow(`Reservation/DetailsDelete`, params);

          this.removeReservationFromCache(id);

        } catch (reason) {
          return prettyResponse(reason,(message)=>{
            switch(message){
              case "Invalid id":
                return "Varausta ei löydy.";
                case "Visit exits":
                  return "Varauksen perusteella on jo kirjauduttu tunnille.";
                default:
                return message;
            }
          });
        }
      },

      async refreshVisits() {
        const authStore = useAuthStore();
        const params = new URLSearchParams([['userId', authStore.id.toString()]]);
        const response = await getNow(`Visit/GetVisits`, params) as AxiosResponse<Array<NewVisitViewModel>>;

        if (response.data.length == 0)
          this.visits = [];
        else {    
          const validatedResponse = new Array<NewVisitViewModel>();
          response.data.forEach(i => validatedResponse.push((new NewVisitViewModel).fromResponse(i)));       
          this.visits = validatedResponse;
        }
        
        return this.visits;
      },

      async getCategories(organizationId: string) {
        const params = new URLSearchParams([['organizationId', organizationId]]);
        try {
            const r = await getNow(`Categories`, params) as AxiosResponse<ListResponse<EventCategoryViewModel>>;

            if(r.data.success===true){
              return r.data.items.sort((i,j)=>(i.orderKey ?? 0) - (j.orderKey ?? 0));
            } else {
              throw r.data.message;
            }
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },  

      async getEventTemplateOptions(ticketTypeId: string, eventTemplateId: string|undefined) {
        const params = new URLSearchParams([['ticketTypeId', ticketTypeId]]);
        if(eventTemplateId) params.append('eventTemplateId',eventTemplateId);
        
        try {

          const r = await getNow(`eventtemplate/SearchOptions`, params) as AxiosResponse<Array<EventTemplateListDto>>;
          const validatedResponse = new Array<EventTemplateListDto>();
          r.data.forEach(i=>validatedResponse.push((new EventTemplateListDto).fromResponse(i)));

          validatedResponse.sort((i,j)=>('' + (i.title ?? "")).localeCompare(j.title ??""));
          return validatedResponse;

        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async getEventTemplates(organizationId: string) {
        const params = new URLSearchParams([['organizationId', organizationId]]);
        
        const r = await getNow(`eventtemplate/search`, params) as AxiosResponse<Array<EventTemplateListDto>>;
        const validatedResponse = new Array<EventTemplateListDto>();
        r.data.forEach(i=>validatedResponse.push((new EventTemplateListDto).fromResponse(i)));

        validatedResponse.sort((i,j)=>('' + (i.title ?? "")).localeCompare(j.title ??""));
        return validatedResponse;

      },  

      async getEventDescriptions(categoryId?: string, organizationId?: string, locationId?: string) {
        const params = new URLSearchParams();
        if(categoryId) params.append('categoryId',categoryId);
        if(organizationId) params.append('organizationId',organizationId);
        if(locationId) params.append('locationId',locationId);
        
        const response = await getNow(`eventtemplate/FilteredList`, params) as AxiosResponse<Array<EventDescription>>;
        if(response.data && response.data.length>0){
          const validatedResponse = new Array<EventDescription>();
          response.data.forEach(i=>validatedResponse.push((new EventDescription).fromResponse(i)));
          return validatedResponse;
        } else {
          throw "Not found";
        }
      }, 
      
      async	getEventInstance(id: string) {
        const params = new URLSearchParams([['id', id.toString()]]);
        const r = await getNow(`eventinstance/get`,params) as AxiosResponse<EventInstanceViewModel>;
        return r.data;
      },

      async updateEventInstance(eventInstance: EventInstanceViewModel) {
        const r = await postNow(`eventinstance/update`, eventInstance) as AxiosResponse<EventInstanceViewModel>;
        if(r.data.id === eventInstance.id){
          return r.data;
        } else {
          throw "Error updating event instance";
        }
      },
      
      async removeEventInstance(id: string) {
        await deleteNow(`eventinstance/${id}`,undefined);
      },  

      // async printReceipt(cartId: string) {
      //   const store = useStore();
      //   try {
      //       const params = new URLSearchParams([['cartId', cartId], ['organizationId', store.state.organization.id]]);
      //       await getNow(`shoppingcart/PrintReceipt`, params);
      //       return;
      //   } catch (reason) {
      //       throw (prettyResponse(reason,undefined));
      //   }
      // },

      // async emailReceipt(cartId: string, email: string) {
      //   const store = useStore();
      //   try {
      //       const params = new URLSearchParams([['cartId', cartId], ['email', email]]);
      //       await getNow(`shoppingcart/EmailReceipt`, params);
      //       return;
      //   } catch (reason) {
      //       throw (prettyResponse(reason,undefined));
      //   }
      // },

    }
  })