

import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonDatetime,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonDatetimeButton,
  IonModal,
  IonSelect, 
  IonSelectOption,
} from "@ionic/vue";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  watch,
  watchEffect,
} from "vue";
import {
  calendarOutline,
  repeatOutline,
  ellipsisHorizontalOutline,
  star, starOutline,
  trash,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import { useStore, ActionTypes, MutationTypes } from "@/store";
import { EventListByLocationDetailed5Result, LocationCalendar, } from "@/models/LocationCalendar";
import { useErrorBox } from "@/components/errorBox";
import { FamilyMemberDetailedViewModel, UserDetailedViewModel, } from "@/models/UserDetailedViewModel";
import * as moment from "moment-timezone";
import { useUserStateUtils } from "@/common/UserStateUtils";
import { emitter } from "@/components/useEvents";
import { EventInstanceUpdated } from "@/models/EventInstanceViewModel";
import { UnsubscribeFunction } from "emittery";
import { CollectionDto } from "@/models/Collections";
import { useCalendarStore } from "@/store/calendarStore";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/authStore";
import CalendarRow from "./CalendarRow.vue";
import { UserActionUpdate } from "@/models/UserActions";
import { computedAsync } from "@vueuse/core";
import { AxiosError } from "axios";

export default defineComponent({
  name: "CalendarView",
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonButton,
    IonDatetime,
    IonIcon,
    IonMenuButton,
    IonRefresher,
    IonRefresherContent,
    IonText,
    IonItemSliding,
    IonItemOption,
    IonItemOptions,
    IonDatetimeButton,
    IonModal,
    IonSelect, 
    IonSelectOption,
    CalendarRow,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const auth = useAuthStore();
    const calendarStore = useCalendarStore();

    const { showError } = useErrorBox();
    const {verifyAuth} = useUserStateUtils();
    const id = router.currentRoute.value.params["id"].toString();

    const momentjs: any = moment;
    moment.locale("fi");

    const calendar: Ref<LocationCalendar | undefined> = ref(undefined);
    const date: Ref<Date> = ref(new Date());
    const processing: Ref<boolean> = ref(true);
    const processing2: Ref<boolean> = ref(false);

    const showAll: Ref<boolean> = ref(false);

    const nextDate: Ref<Date | undefined> = ref(undefined);

    const collections: Ref<Array<CollectionDto> | undefined> = ref(undefined);
    
    const { calendarCollections } = storeToRefs(calendarStore)

    const organization = computed(() => store.state.organization);

    const customer: Ref<UserDetailedViewModel | undefined> = ref(undefined);
    const familyMember: Ref<FamilyMemberDetailedViewModel | undefined> = ref(undefined);

    const hasRoleInstructor = computed(() => {
      return (
        auth.roles?.indexOf("Instructor") >= 0 ||
        auth.roles?.indexOf("Merchant") >= 0 ||
        auth.roles?.indexOf("Admin") >= 0
      );
    });

    const reservations = computedAsync( async () => {
      if(!customer.value || !familyMember.value || !customer.value.userId)
        return [];

      if(customer.value.userId == auth.id)
        return calendarStore.reservations;

      return await calendarStore.getReservations(customer.value.userId);
    }, []);


    const loadCalendar = async (_firstDate: Date, _organizationId: string) => {
      if(!customer.value || !familyMember.value)
        return;

      processing.value = true;
      try {
        const response = await calendarStore.getCalendarForMemeber(
          store.state.organization.id,
          undefined,          
          date.value,
          4,
          customer.value.userId,
          familyMember.value.id,
          calendarCollections.value
        );

        if (response)
            calendar.value = response;
         
      } catch (err) {
        
        if(err instanceof AxiosError && err.response?.status == 401){
          verifyAuth();
          return;
        }

        showError(err,  "virhe");
      } finally {
        processing.value = false;
        nextDate.value = new Date(momentjs(date.value).add(4, "days").startOf('day'));
      }
    };

    const loadMore = async () => {
      if(!customer.value || !familyMember.value)
        return;

        try {          
          const response = await calendarStore.getCalendarForMemeber(
            store.state.organization.id,
            undefined,          
            nextDate.value,
            1,
            customer.value.userId,
            familyMember.value.id,
            calendarCollections.value
          );

          if (response && response.days)
            calendar.value?.days?.push(...response.days);
          nextDate.value = momentjs(nextDate.value).add(1, "days");
        } catch (err) {
          showError(err as string,  "virhe");
        }
    };

    watchEffect(() => {
      if (!store.state.organization.id || store.state.organization.id === "") {
        return;
      }
      const _id = familyMember.value?.id;
      loadCalendar(date.value, store.state.organization.id);
    });

    watch(
      () => store.state.fullProfile?.userId,
      async (userId, _oldId) => {
        if (id !== "") return;

        if (!userId) {
          customer.value = undefined;
          familyMember.value = undefined;
        } else {
          customer.value = store.getters.fullProfile;
          familyMember.value = store.getters.familyMember;
        }
      }
    );

    let emitterDismiss: UnsubscribeFunction|undefined = undefined;
    let emitterDismiss2: UnsubscribeFunction|undefined = undefined;

    onMounted(() => {

      // follow eventInstanceUpdated events
      emitterDismiss = emitter.on('eventInstanceUpdated',async (data: EventInstanceUpdated) => {
        debugger;
        calendar.value?.days?.forEach((day)=>{
          day.events.forEach(e => {
            if(e.instanceID == data.id){
              e.reservations = data.reservations;
              e.visits = data.visits;              
              e.comments = data.comments;
            }
          });
        })
      });

      // follow reserve events
      emitterDismiss2 = emitter.on('reserve',async (data: UserActionUpdate) => {
        // debugger;
        calendar.value?.days?.forEach((day)=>{
          day.events.forEach(e => {            
            if(e.instanceID == data.reserve?.eventId && e.reservations){
              debugger;
              e.reservations++;
              e.reservations--;
            }
          });
        })
      });

      store.dispatch(ActionTypes.GET_COLLECTIONS, "CALENDAR")
        .then((result) => { collections.value = result; })
        .catch((err) => { showError(err.message,  "virhe"); });
      
      date.value = new Date();

      if (id == "" || !hasRoleInstructor.value) {
        if(store.getters.fullProfile) {
          customer.value = store.getters.fullProfile;
          familyMember.value = store.getters.familyMember;
          } else {
            customer.value = undefined;
            familyMember.value = undefined;
            verifyAuth();
          }
      } else {
        store
          .dispatch(ActionTypes.GET_FULL_PROFILE, parseInt(id))
          .then((result) => {
            customer.value = result;
            familyMember.value = result.familyMembers[0];
          })
          .catch((err) => {
            showError(err.message,  "virhe");
          });
      }
    });

    onUnmounted(async ()=>{
      if(emitterDismiss) emitterDismiss();
      if(emitterDismiss2) emitterDismiss2();
    });
    
    const selectDate = (message: CustomEvent) => {
      const d = momentjs(message.detail.value);
      date.value = d;
    };

    const refreshNow = async (item: any) => {
      console.log(id);
      if (id === ""){
        await calendarStore.refreshReservations();
      }

      loadCalendar(date.value, store.state.organization.id);
      item.target.complete();
    };

    const favorite = (eventInstance: EventListByLocationDetailed5Result) => {
      if(!eventInstance.templateID) return;
      store.commit(MutationTypes.TOGGLE_FAVORITE, eventInstance.templateID);
    };

    const hide = (eventInstance: EventListByLocationDetailed5Result) => {
      if(!eventInstance.templateID) return;
      store.commit(MutationTypes.TOGGLE_HIDDEN, eventInstance.templateID);
    };

    const isFavorite = (eventInstance: EventListByLocationDetailed5Result) => {
      if(!eventInstance.templateID) return true;

      if(store.state.favoriteTypes.indexOf(eventInstance.templateID) >= 0)
        return true;

      return false;
    };

    const isHidden = (eventInstance: EventListByLocationDetailed5Result) => {
      if(!eventInstance.templateID) return true;

      if(store.state.hiddenTypes.indexOf(eventInstance.templateID) >= 0)
        return true;

      return false;
    };

    const nextFamilyMember = () => {
      if (!familyMember.value || !customer.value) return;

      const updateFamilyMember =
        store.state.familyMember?.id &&
        familyMember.value.id == store.state.familyMember.id;

      let index = customer.value.familyMembers.findIndex(
        (i) => i.id == familyMember.value?.id
      );
      index++;
      if (index == customer.value.familyMembers.length || index < 0) index = 0;

      familyMember.value = customer.value.familyMembers[index];

      if (updateFamilyMember) store.state.familyMember = familyMember.value;
    };

    const multiMembers = computed(() => {
      return !!familyMember.value && !!customer.value && customer.value.familyMembers.length > 1;
    });

    const loadData = async (ev: any) => {
      await loadMore();
      ev.target.complete();
    };

    return {
      router,
      momentjs,
      calendar,
      organization,
      selectDate,
      calendarOutline,
      repeatOutline,
      ellipsisHorizontalOutline,
      date,
      hasRoleInstructor,
      processing,
      processing2,
      refreshNow,
      familyMember,customer,
      nextFamilyMember, reservations,
      multiMembers,
      loadData,
      favorite, hide, isHidden, isFavorite, showAll,
      star, trash,starOutline,
      collections,calendarCollections,
    };
  },
});
