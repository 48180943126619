import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-83df0e0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_ctx.fullDetails)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_text, { color: "secondary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.fullDetails.title), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.calendarOutline }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.momentjs(_ctx.fullDetails.beginTime).calendar()) + "-" + _toDisplayString(_ctx.momentjs(_ctx.fullDetails.endTime).format('H:mm')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.locationOutline }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.fullDetails.location?.title), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.personOutline }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.familyMember?.firstName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
          default: _withCtx(() => [
            (_ctx.canSignIn)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  onClick: _ctx.signInNow,
                  size: "default"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.logInOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.reservation && !_ctx.visit)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 1,
                  disabled: !_ctx.canCancelReservation,
                  color: "warning",
                  size: "default",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.unRegisterEvent()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true),
            (_ctx.visit)
              ? (_openBlock(), _createBlock(_component_ion_text, { key: 2 }, {
                  default: _withCtx(() => [
                    _createTextVNode("Kirjauduttu " + _toDisplayString((_ctx.reservation?.quantity ?? 0) > 1 ? (_ctx.visit.quantity + 'x') : "") + " " + _toDisplayString(_ctx.momentjs(_ctx.visit?.visitTime).format('H:mm')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!_ctx.fullDetails || !_ctx.familyMember)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "ladataan"))
          : _createCommentVNode("", true)
      ], 64))
}