import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_ctx.fullDetails)
    ? (_openBlock(), _createBlock(_component_ion_text, {
        key: 0,
        style: _normalizeStyle([_ctx.style, {"text-wrap":"nowrap"}]),
        color: _ctx.color
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            icon: _ctx.icons.locationOutline
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.fullDetails?.title), 1)
        ]),
        _: 1
      }, 8, ["style", "color"]))
    : (_openBlock(), _createBlock(_component_ion_text, {
        key: 1,
        style: _normalizeStyle(_ctx.style),
        color: _ctx.color
      }, null, 8, ["style", "color"]))
}