

import { IonButton, IonIcon, IonText, loadingController } from "@ionic/vue";
import { computed, defineComponent, PropType } from "vue";
import { peopleOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import { EventListByLocationDetailed5Result} from "@/models/LocationCalendar";
import { useErrorBox } from "@/components/errorBox";
import { NewReservationRequest, ReservationViewModel } from "@/models/ReservationViewModel";
import * as moment from "moment-timezone";
import LocationItem from "@/views/LocationItem.vue";
import InstructorItem from "@/views/InstructorItem.vue";
import { useAuthStore } from "@/store/authStore";
import { useCalendarStore } from "@/store/calendarStore";

export default defineComponent({
  name: "CalendarRow",
  components: {
    LocationItem,
    InstructorItem,
    IonButton,
    IonIcon,
    IonText,
  },
  props: {
    eventInstance: { type: Object as PropType<EventListByLocationDetailed5Result>, required: true},
    reservations: { type: Object as PropType<Array<ReservationViewModel>>, required: true},
    familyMemberId: { type: String, required: true},
    userId: { type: Number, required: true},
  },
  setup(props) {
    const router = useRouter();
    const auth = useAuthStore();
    const calendarStore = useCalendarStore();

    const { showError } = useErrorBox();

    const momentjs: any = moment;
    moment.locale("fi");


    const hasRoleInstructor = computed(() => {
      return (
        auth.roles?.indexOf("Instructor") >= 0 ||
        auth.roles?.indexOf("Merchant") >= 0 ||
        auth.roles?.indexOf("Admin") >= 0
      );
    });

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }    

    const registerToEvent = async ( ) => {
      if (!props.eventInstance?.instanceID) {
        showError("Tuntia ei löydy.",  "virhe");
        return;
      }

      const data = new NewReservationRequest();
      data.eventInstanceId = props.eventInstance.instanceID;
      data.quantity = 1;
      data.userId = props.userId;
      data.familyMemberId = props.familyMemberId;
      
      const l = await loading();

      try {
        calendarStore.addReservation(data);
      } catch (err) {
        await showError(err as string,  "virhe");
      } finally {
        l.dismiss();      
      }
    };

    const unRegisterEvent = async () => {
      if (!props.eventInstance?.instanceID) {
        showError("Tuntia ei löydy.",  "virhe");
        return;
      }

      const e = props.reservations.find((i) => i.eventInstanceId === props.eventInstance.instanceID);
      if (!e) {
        calendarStore.refreshReservations();
        return;
      }

      const l = await loading();

      try {
        await calendarStore.removeReservation(e.id);
      } catch (err){
        showError(err as string,  "virhe");
        await calendarStore.refreshReservations();
      } finally {
        l.dismiss();
      }
    };

    const isRegistered = computed (() => {
      const r = props.reservations.find( (i) =>
            i.eventInstanceId === props.eventInstance.instanceID &&
            i.familyMemberId === props.familyMemberId );
      return r?.quantity ?? 0;
    });

    const canReserveMore = computed( () => {
      const r = props.reservations.find( (i) =>
            i.eventInstanceId === props.eventInstance.instanceID &&
            i.familyMemberId === props.familyMemberId );

      if(!r) return  props.eventInstance.reserveSlots ?? 1;
      return ( props.eventInstance.reserveSlots ?? 1) - r.quantity;
    });

    const timeMinutes = () => {
      return Math.abs(new Date(props.eventInstance.beginTime).getTime() - new Date(props.eventInstance.endTime).getTime()) / 36e5 * 60;
    }

    const reserveScreen = () => {
      router.push(`/reserve/${props.eventInstance.instanceID}`);
    };

    const showPartisipants = () => {
      router.push(`/partisipants/${props.eventInstance.instanceID}`);
    };

    const canReserve = computed(() => {
      if (!props.eventInstance.reservationRequired || !props.familyMemberId)
        return false;

      return new Date(props.eventInstance.beginTime) > new Date();
    });

    const reserveColNeeded = computed(() => {
        if(hasRoleInstructor.value) return true;
        if(canReserve.value) return true;
        if(props.eventInstance.reservationRequired && canReserveMore && canReserve.value) return true;
        if(props.eventInstance.reservationRequired && isRegistered.value && canReserve.value) return true;
      return false;
    });

    return {
      reserveColNeeded,
      router,timeMinutes,
      momentjs,
      registerToEvent,
      unRegisterEvent,
      isRegistered, canReserveMore,
      canReserve,
      reserveScreen,
      hasRoleInstructor,
      showPartisipants,
      peopleOutline,
    };
  },
});
