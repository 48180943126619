
import { IonIcon, IonText } from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref } from 'vue';
import { personOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'InstructorItem',
  props: {
    data: { type: String },
    style: { type: Object, default() { return {} } },
    color: { type: String as PropType<PredefinedColors>, default: "primary" },
  },
  components: { IonText, IonIcon },
  setup(props) {

    //const store = useStore();
    const name: Ref<string | undefined> = ref(undefined);
    const id: Ref<string | undefined> = ref(undefined);

    onMounted(() => {
      if (props.data && props.data.length > 1) {
        const m = props.data.match(/^([^(]+)\((.+)\)$/);
        if (m && m.length === 3) {
          name.value = m[1];
          id.value = m[2];
        }
      }
    });
    return {
      name,
      icons: {
        personOutline
      }
    }
    }
});
