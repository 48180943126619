
import { IonIcon } from '@ionic/vue';
import { defineComponent, PropType } from 'vue';
import * as moment from 'moment-timezone';
import { TicketInfo } from '@/models/Ticket';
import { FamilyMemberViewModel } from '@/models/UserDetailedViewModel';
import { calendarOutline, pricetagOutline, personOutline, cartOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'TicketButton',
  props: {
    ticket: { type: Object as PropType<TicketInfo>, required: false },
    member: { type: Object as PropType<FamilyMemberViewModel>, required: false },
  },
  components:{  
    // IonText,
    IonIcon,
  },
  setup() {

  const momentjs: any = moment;
  moment.locale("fi");

  return {
    momentjs,
    cartOutline,personOutline,pricetagOutline,calendarOutline,
  };
  }
});
