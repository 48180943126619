import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6136a093"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ticket-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_TicketButton = _resolveComponent("TicketButton")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { id: "profilePage" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  (_ctx.customer)
                    ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.customer.userId), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  (_ctx.customer)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _ctx.save
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(" tallenna")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (!_ctx.customer)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(" Ladataan ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.customer)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-no-padding" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    sizeLg: "8",
                    class: "ion-no-padding"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item_divider, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Yhteystiedot")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_grid, { class: "ion-no-padding" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.userName,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.userName) = $event)),
                                        label: "käyttäjätunnus",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.email,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.email) = $event)),
                                        label: "sähköposti",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.phone,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.phone) = $event)),
                                        label: "puhelin",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.firstName,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.firstName) = $event)),
                                        label: "etunimi",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.lastName,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.lastName) = $event)),
                                        label: "sukunimi",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.street,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.street) = $event)),
                                        label: "katuosoite",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.postCode,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customer.postCode) = $event)),
                                        label: "postinumero",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.city,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customer.city) = $event)),
                                        label: "kaupunki",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.country,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customer.country) = $event)),
                                        label: "maa",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.companyName,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.customer.companyName) = $event)),
                                        label: "yritys",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_input, {
                                        modelValue: _ctx.customer.vatCode,
                                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.customer.vatCode) = $event)),
                                        label: "y tunnus",
                                        labelPlacement: "stacked"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_text, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Ennakko " + _toDisplayString(_ctx.customer.sportsCredit.toFixed(2)) + "€", 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item_divider, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Jäsenet")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customer.familyMembers, (familyMember) => {
                        return (_openBlock(), _createBlock(_component_ion_grid, {
                          key: familyMember.id,
                          class: "ion-no-padding",
                          style: {"padding-top":"35px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_item, { lines: "none" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_input, {
                                          modelValue: familyMember.firstName,
                                          "onUpdate:modelValue": ($event: any) => ((familyMember.firstName) = $event),
                                          label: "etunimi",
                                          labelPlacement: "stacked"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_item, { lines: "none" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_input, {
                                          modelValue: familyMember.lastName,
                                          "onUpdate:modelValue": ($event: any) => ((familyMember.lastName) = $event),
                                          label: "sukunimi",
                                          labelPlacement: "stacked"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_col, { class: "datetime-container" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, { style: {"font-size":"smaller"} }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("syntymäaika")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_datetime_button, {
                                      datetime: familyMember.id + 'datetime'
                                    }, null, 8, ["datetime"]),
                                    _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_datetime, {
                                          id: familyMember.id + 'datetime',
                                          presentation: "date",
                                          "prefer-wheel": true,
                                          value: familyMember.birthDay,
                                          onIonChange: ($event: any) => (_ctx.selectDate(familyMember, $event))
                                        }, null, 8, ["id", "value", "onIonChange"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_item, { lines: "none" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_input, {
                                          placeholder: "kommentit",
                                          modelValue: familyMember.comments,
                                          "onUpdate:modelValue": ($event: any) => ((familyMember.comments) = $event),
                                          label: "muistiinpanot",
                                          labelPlacement: "stacked"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            (_ctx.orderedTickets(familyMember.tickets).length>0)
                              ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_1, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedTickets(familyMember.tickets), (ticket) => {
                                        return (_openBlock(), _createBlock(_component_TicketButton, {
                                          key: ticket.id,
                                          ticket: ticket
                                        }, null, 8, ["ticket"]))
                                      }), 128))
                                    ])
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    sizeLg: "4"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}