
import { EventTemplateListDto } from '@/models/EventTemplate';
import { TicketChangeModel, TicketInfo } from '@/models/Ticket';
import { 
  IonTextarea, 
  IonContent, 
  IonHeader,
  IonTitle,
  IonToolbar, 
  IonInput, 
  IonButton, 
  IonItem, 
  IonDatetime, 
  IonList,
  IonText,
  IonSelect,
  IonSelectOption, 
  IonIcon,
  IonCol,
  IonRow,
  IonButtons,
loadingController,
  } from '@ionic/vue';
import { trashOutline, } from 'ionicons/icons';
import { defineComponent, ref , onMounted, Ref, watch, PropType, computed} from 'vue';
import * as moment from 'moment-timezone';
import { TicketStateInfo } from '@/models/TicketInfo';
import { TicketType } from '@/models/TicketType';
import { useErrorBox } from "@/components/errorBox";
import { useCalendarStore } from '@/store/calendarStore';
import { useTicketStore } from '@/store/ticketStore';
import { storeToRefs } from 'pinia';
export default defineComponent({
  name: 'CardEdit',
  props: {
    ticket: { type: Object as PropType<TicketInfo>, required: true}
  },
  components: { 
    IonTextarea, 
    IonContent, 
    IonHeader, 
    IonTitle, 
    IonToolbar, 
    IonInput,
    IonButton,
    IonItem, 
    IonDatetime,
    IonSelect,
    IonSelectOption, 
    IonList,
    IonText,
    IonIcon,
    IonCol,
    IonRow,
    IonButtons,
   },
  emits:
    ['update-ticket','cancel'],
  setup(props, context) {
    const calendarStore = useCalendarStore();
    const ticketStore = useTicketStore();
    const {showError} = useErrorBox();
    const currentTicket: Ref<TicketInfo|undefined> = ref(new TicketInfo());
    const eventTemplateOptions: Ref<Array<EventTemplateListDto>> = ref([]);
    const changes: Ref<Array<TicketChangeModel>> = ref([]);
    const visits: Ref<Array<Date>> = ref([]);
    const momentjs: any = moment;
    moment.locale("fi");

    const { ticketTypes } = storeToRefs(ticketStore);

    const loading = async () => {
      const loading = await loadingController.create({ message: 'Hetki...', duration: undefined, });
      await loading.present();
      return loading;
    }
  
    onMounted( async () =>  {
      currentTicket.value = new TicketInfo().fromResponse(JSON.parse(JSON.stringify(props.ticket)));
      const l = await loading();
      try {

        debugger;
        if(!ticketTypes.value || ticketTypes.value.length === 0)
          await ticketStore.loadTicketTypes();
        
        if(currentTicket.value.ticketId) {
          const r = await calendarStore.getEventTemplateOptions(currentTicket.value.ticketId, currentTicket.value.eventTemplateId);
          if(r) eventTemplateOptions.value = r;
        }

        if(!currentTicket.value.id) return;

        const r2 = await ticketStore.getTicketDetails(currentTicket.value.id);
        if(r2){ 
            changes.value = r2.changes;
            visits.value = r2.visits;
          }        
      } catch(err){
        showError(err as string, "virhe");
      } finally {
        l.dismiss();
      }
    });

    const activeTicketTypes = computed(() => ticketTypes.value.filter(t=>t.isAvailable || t.id === currentTicket.value?.ticketId));

    watch(() => currentTicket.value?.ticketId, (id, _oldId) => {
      if(!id) return;

      calendarStore.getEventTemplateOptions(id, currentTicket.value?.eventTemplateId)
        .then((result) => { 
          eventTemplateOptions.value = result; })
        .catch((err)=>{console.log(err)});
    });

    const ticketStates = new Array<TicketStateInfo> (
      { code: "Draft", title: "Kortti ei voimassa. Varaus kesken tms." },
      { code: "Reserved", title: "Kortti ei maksettu. Paikka varattuna." },
      { code: "Ready", title: "Käyttämätön: Voimassaolo nollataan ja asetetaan uudestaan kirjautuessa." },
      { code: "Active", title: "Käytössä: Voimassaolo asetettu oikeaksi, ei aseteta kirjautuessa." },
      { code: "Paused", title: "Tauolla: Korttia ei voi käyttää toistaiseksi." },
      { code: "Used", title: "Kortin kaikki käynnit käytetty loppuun." },
      { code: "Expired", title: "Kortti on vanhentunut." },
      { code: "Cancelled", title: "Kortti on peruttu tai poistettu käytöstä." },
    );

    const saveChanges = ()=> {
      context.emit('update-ticket', currentTicket);
    };

    const cancelChanges = ()=> {
      context.emit('cancel');
    };

    const getTemplate2Title = (e: TicketType) => {
       const t = e.name;
       return t;
    }

    const getTemplateTitle = (e: EventTemplateListDto) => {
       let t = e.title;
       if(e.ageMin){ t = t + " " + e.ageMin; }
       t = t + "-";
      if(e.ageMax){ t = t + " " + e.ageMax; }

      if(e.nexTime) { t = t + " " + momentjs(e.nexTime).format('(dddd HH:mm DD.MM.)');}
      else { t = t + " (ei tunteja)"}
       return t;
    }
    return { 
      currentTicket, activeTicketTypes,
      saveChanges,cancelChanges,
      getTemplateTitle,
      getTemplate2Title,
      eventTemplateOptions,
      visits,
      changes,
      ticketStates,
      ticketTypes,
      momentjs,
      trashOutline,
      }
  },
  methods: { }
});

