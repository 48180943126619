import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-16823302"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex-cont-row p-5-a",
  style: {"justify-content":"space-between","width":"100%","padding-left":"3px"}
}
const _hoisted_2 = {
  class: "flex-cont-col p-5-a",
  style: {"flex":"0 0","justify-content":"space-around","min-width":"65px"}
}
const _hoisted_3 = {
  class: "headRow",
  style: {"white-space":"nowrap"}
}
const _hoisted_4 = { class: "secondRow" }
const _hoisted_5 = {
  class: "flex-cont-col p-5-a",
  style: {"flex":"1 1","justify-content":"space-around"}
}
const _hoisted_6 = { class: "headRow" }
const _hoisted_7 = {
  class: "secondRow",
  style: {"display":"flex","flex-flow":"row wrap"}
}
const _hoisted_8 = { class: "headRow" }
const _hoisted_9 = {
  class: "secondRow",
  style: {"display":"flex","flex-flow":"row wrap","justify-content":"space-around"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_LocationItem = _resolveComponent("LocationItem")!
  const _component_InstructorItem = _resolveComponent("InstructorItem")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.momentjs(new Date(_ctx.eventInstance.beginTime)).format("H:mm")), 1),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.timeMinutes() <= 60)
          ? (_openBlock(), _createBlock(_component_ion_text, {
              key: 0,
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.timeMinutes()) + " min", 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_ion_text, {
              key: 1,
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" - " + _toDisplayString(new Date(_ctx.eventInstance.endTime).getHours()) + ":" + _toDisplayString(String(new Date(_ctx.eventInstance.endTime).getMinutes()).padStart(2,"0")), 1)
              ]),
              _: 1
            }))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.eventInstance.title) + " ", 1),
        (_ctx.eventInstance.comments)
          ? (_openBlock(), _createBlock(_component_ion_text, {
              key: 0,
              color: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.eventInstance.comments), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_LocationItem, {
          style: {"flex":"0 0 50%"},
          id: _ctx.eventInstance.locationId
        }, null, 8, ["id"]),
        _createVNode(_component_InstructorItem, {
          style: {"flex":"0 0 50%"},
          data: _ctx.eventInstance.instructors
        }, null, 8, ["data"])
      ])
    ]),
    (_ctx.reserveColNeeded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex-cont-col p-5-y", {'instructor-col':!_ctx.hasRoleInstructor}]),
          style: {"flex":"1 1","justify-content":"space-around","min-width":"80px","max-width":"120px"}
        }, [
          _createElementVNode("div", _hoisted_8, [
            (_ctx.eventInstance.reservationRequired && _ctx.canReserveMore &&
          _ctx.canReserve)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  class: "m-y-a",
                  fill: "clear",
                  expand: "block",
                  color: "success",
                  style: {"min-height":"30px"},
                  size: "default",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.registerToEvent()))
                }, {
                  default: _withCtx(() => [
                    ((_ctx.eventInstance.reserveSlots ?? 1) < 2)
                      ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode("varaa")
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode("varaa " + _toDisplayString(_ctx.isRegistered) + " / " + _toDisplayString(_ctx.eventInstance.reserveSlots ?? 1), 1)
                          ]),
                          _: 1
                        }))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.eventInstance.reservationRequired && _ctx.isRegistered &&
          _ctx.canReserve)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 1,
                  class: "m-y-a",
                  fill: "clear",
                  expand: "block",
                  color: "warning",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.unRegisterEvent()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("peru " + _toDisplayString(_ctx.isRegistered), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.hasRoleInstructor)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  class: "m-y-a",
                  fill: "clear",
                  expand: "block",
                  size: "small",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPartisipants()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.peopleOutline,
                      style: {"min-width":"16px"}
                    }, null, 8, ["icon"]),
                    _createTextVNode("  " + _toDisplayString(_ctx.eventInstance.reservations) + " / " + _toDisplayString(_ctx.eventInstance.visits) + " / " + _toDisplayString(_ctx.eventInstance.partisipants), 1)
                  ]),
                  _: 1
                }))
              : (_ctx.canReserve)
                ? (_openBlock(), _createBlock(_component_ion_text, {
                    key: 1,
                    color: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString((_ctx.eventInstance.partisipants ?? 0) - (_ctx.eventInstance.reservations ?? 0)) + " paikkaa", 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}